import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    class: _normalizeClass(_ctx.appButtonClass),
    style: _normalizeStyle(_ctx.appButtonStyle),
    mode: "md",
    props: _ctx.$props,
    fill: _ctx.fill,
    "data-testid": "app-button"
  }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ion_spinner, {
            key: 0,
            name: "crescent",
            "data-testid": "app-button-spinner"
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.to)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: _ctx.to,
                  replace: _ctx.replaceRoute
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default", {}, () => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
                    ], true)
                  ]),
                  _: 3
                }, 8, ["to", "replace"]))
              : _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
                ], true)
          ], 64))
    ]),
    _: 3
  }, 8, ["class", "style", "props", "fill"]))
}