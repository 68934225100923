<script setup lang="ts">
import getRandomReference from '@/shared/helpers/getRandomReference/getRandomReference';

const uuid = getRandomReference();
</script>
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.3001 4L21.8001 5.4C22.3001 6.8 21.8001 8.1 20.8001 9C20.2001 9.4 19.5001 9.7 18.6001 9.7C17.5001 9.7 16.6001 9.2 16.0001 8.5C15.8001 8.3 15.6001 8.2 15.3001 8.2C15.0001 8.2 14.8001 8.3 14.6001 8.5C14.0001 9.2 13.1001 9.7 12.0001 9.7C10.9001 9.7 10.0001 9.2 9.50013 8.4C9.30013 8.2 9.00013 8.1 8.80013 8.1C8.50013 8.1 8.30013 8.2 8.10013 8.4C7.50013 9.1 6.60013 9.6 5.50013 9.6C4.70013 9.6 3.90013 9.4 3.30013 8.9C2.20013 8.1 1.70013 6.7 2.20013 5.4L2.70013 4C2.90013 3.4 3.50013 3 4.20013 3H19.8001C20.5001 3 21.1001 3.4 21.3001 4ZM12 11.5C13.1 11.5 14.2 11.2 15 10.5C15.8 11.2 16.8 11.6 18 11.6C18.7 11.6 19.4 11.4 20 11.1V17.1C20 19.2 18.3 21 16.2 21H14.1C13.6 21 13.2 20.5 13.2 20V17C13.2 16.3 12.7 15.7 12 15.7C11.3 15.7 10.8 16.3 10.8 17V20C10.8 20.6 10.4 21 9.9 21H7.8C5.7 21 4 19.2 4 16.9V11C4.6 11.3 5.3 11.5 6 11.5C7.1 11.5 8.2 11.2 9 10.5C9.9 11.1 10.9 11.5 12 11.5Z"
      :fill="`url(#${uuid}_paint0_linear_7892_235175)`"
      style=""
    />
    <defs>
      <linearGradient
        :id="`${uuid}_paint0_linear_7892_235175`"
        x1="18.9561"
        y1="-0.866668"
        x2="-1.30219"
        y2="16.2073"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#982AA2"
          style="
            stop-color: #982aa2;
            stop-color: color(display-p3 0.5961 0.1647 0.6353);
            stop-opacity: 1;
          "
        />
        <stop
          offset="1"
          stop-color="#0060FF"
          style="
            stop-color: #0060ff;
            stop-color: color(display-p3 0 0.3765 1);
            stop-opacity: 1;
          "
        />
      </linearGradient>
    </defs>
  </svg>
</template>
