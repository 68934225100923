import axios from '@/plugins/http/axios';
import { ApiResponse, IndexedList } from '@/shared/types/generic';
import { User } from '@/shared/types/user';
import { AxiosResponse } from 'axios';

export default {
  verifyPassword(password: string): Promise<AxiosResponse> {
    const body = {
      password: window.btoa(password),
    };

    return axios.post('/auth/verify-password', body);
  },

  changePassword(
    email: string,
    password: string,
    otp?: string,
  ): Promise<AxiosResponse> {
    const body = otp
      ? {
          email,
          password: window.btoa(password),
          otp,
        }
      : {
          email,
          password: window.btoa(password),
        };

    return axios.post('/users/change-password', body);
  },

  recoverPassword(email: string): Promise<AxiosResponse> {
    return axios.post('/users/recover-password', { email });
  },

  verifyCode(email: string, code: string): Promise<AxiosResponse> {
    return axios.post('/users/verify-otp', { email, otp: code });
  },

  getIndexedUsers(
    name: string,
    isQueryByTeamName: boolean,
  ): Promise<ApiResponse<IndexedList<User>>> {
    return axios.get('/users/search-operators', {
      params: { name, isQueryByTeamName },
    });
  },

  deleteUser(): Promise<AxiosResponse> {
    return axios.delete('/users/worker');
  },
};
