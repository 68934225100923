import { ToastOptions } from '@ionic/vue';

export interface BusinessError {
  code: number;
  message: string;
}
export class ResponseError extends Error {
  public status: number;

  public reason: BusinessError;

  constructor(status: number, data: BusinessError) {
    super(data.message);

    this.status = status;
    this.reason = data;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isABusinessError(obj: any): obj is BusinessError {
    return obj && 'code' in obj && 'message' in obj;
  }
}

export type ToastType =
  | 'info'
  | 'warning'
  | 'danger'
  | 'success'
  | 'achievement';

export interface InnerNotification {
  message: string;
  type: ToastType;
  moreOptions?: ToastOptions;
  onClick?: () => void;
}

export enum DatePeriod {
  ZERO = 0,
  SECOND = 1000,
  MINUTE = 60000,
  HOUR = 3600000,
  DAY = 86400000,
  WEEK = 604800000,
  MONTH = 2592000000,
}
