import { CollectableTypeName, Item, ItemType } from '@/shared/types/profile';
import { ResponseMetadata } from '@/shared/types/generic';

export enum ItemOrigin {
  PRIZE = 'prize',
  COLLECTABLE = 'collectable',
}

export interface CollectableType {
  name: CollectableTypeName;
  reference: string;
}

export interface StoreItem extends Item {
  price: number;
  stockNumber?: number;
  purchased: boolean;
  tagName: string;
}

export interface StoreResponse {
  metadata: ResponseMetadata;
  results: {
    storeItems: StoreItem[];
  };
}

export interface StoreRequest {
  type?: ItemType;
  limit?: number;
}

export interface CollectablesRequest {
  tagReference: string;
  typeReference: string;
}

export enum StoreStatus {
  ALL = 'all',
  AVAILABLE = 'available',
  SCHEDULED = 'scheduled',
  HIDDEN = 'hidden',
}

export enum StoreStatusColors {
  GREEN_100 = '#BEE7D9',
  BLUE_50 = '#E6EFFF',
  GRAY_100 = '#E1E2E2',
}

export enum PurchaseStatus {
  ACQUIRED = 'acquired',
  REFUNDED = 'refunded',
}

export interface PurchasedItem {
  itemImageUrl: string;
  name: string;
  realPrice: number;
  currencySymbol: string;
  coins: number;
  stock?: number;
  userImageUrl?: string;
  username: string;
  purchaseReference: string;
  purchaseDate: number;
  purchaseStatus: PurchaseStatus;
  itemOrigin: ItemOrigin;
  prependSymbol: boolean;
  category: string;
  refundUsername?: string;
  refundUserImageUrl?: string;
  refundDate?: number;
  refundComment?: string;
  itemReference: string;
}

export interface CategorizedOption {
  tagName: string;
  tagReference: string;
  storeItems: StoreItem[];
}

export interface CollectablesResponse {
  items: StoreItem[];
  numberOfItems: number;
  numberOfPurchased: number;
  tagName: string;
}
