<script setup lang="ts">
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: '#3C3B39',
});
</script>
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0652 4.63H16.2391C14.9348 4.63 13.6304 4.08 12.7609 3.2C11.4565 1.77 9.71739 1 7.76087 1H4.82609C3.30435 1 2 2.32 2 3.86V4.74V13.54V21.9C2 22.56 2.43478 23 3.08696 23C3.73913 23 4.17391 22.56 4.17391 21.9V13.65V4.85V3.86C4.17391 3.53 4.5 3.2 4.82609 3.2H7.76087C9.06522 3.2 10.3696 3.75 11.2391 4.63C12.5435 5.95 14.3913 6.72 16.2391 6.72H19.0652C19.5 6.72 19.7174 7.05 19.7174 7.38V15.3C19.7174 15.74 19.3913 15.96 19.0652 15.96H16.2391C14.9348 15.96 13.6304 15.41 12.7609 14.53C11.4565 13.21 9.6087 12.44 7.76087 12.44H6.67391C6.02174 12.44 5.58696 12.88 5.58696 13.54C5.58696 14.2 6.02174 14.64 6.67391 14.64H7.86957C9.17391 14.64 10.4783 15.19 11.3478 16.07C12.6522 17.39 14.5 18.16 16.3478 18.16H19.1739C20.8043 18.16 22 16.84 22 15.3V7.49C22 5.95 20.6957 4.63 19.0652 4.63Z"
      :fill="props.color"
    />
  </svg>
</template>
