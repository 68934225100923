export const parseNumberString = (number: string): number => {
  return parseInt(number.replace(/[,.]/g, ''), 10);
};

export const removeExtraSpaces = (value: string): string => {
  return value.replace(/(\s{2,})/gm, ' ').trim();
};

export function isEmpty(payload: unknown): boolean {
  return (
    payload === null ||
    payload === undefined ||
    (typeof payload === 'string' && payload.trim().length === 0) ||
    (Array.isArray(payload) && payload.length === 0) ||
    (typeof payload === 'object' &&
      !Array.isArray(payload) &&
      Object.keys(payload).length === 0)
  );
}

export function maskEmail(email: string): string {
  const [username, domain] = email.split('@');
  if (!username || !domain) return '';

  const firstChar = username.slice(0, 1);
  const asterisks = '*'.repeat(username.length - 2);
  const lastChar = username.slice(-1);

  return `${firstChar + asterisks + lastChar}@${domain}`;
}
