<script setup lang="ts">
import getRandomReference from '@/shared/helpers/getRandomReference/getRandomReference';

const uuid = getRandomReference();
</script>
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 21H6.99998C6.53998 21 6.13998 20.69 6.02998 20.24L3.02998 8.24C2.91998 7.8 3.11998 7.35 3.50998 7.13C3.89998 6.91 4.38998 6.97 4.70998 7.29L8.14998 10.73L11.08 3.62C11.23 3.25 11.6 3 12 3C12.4 3 12.77 3.24 12.92 3.62L15.85 10.73L19.29 7.29C19.61 6.97 20.1 6.9 20.49 7.13C20.88 7.35 21.08 7.81 20.97 8.24L17.97 20.24C17.86 20.68 17.46 21 17 21Z"
      :fill="`url(#${uuid}_paint0_linear_7892_235164)`"
      style=""
    />
    <defs>
      <linearGradient
        :id="`${uuid}_paint0_linear_7892_235164`"
        x1="18.2625"
        y1="-0.866668"
        x2="-1.53451"
        y2="14.1572"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#982AA2"
          style="
            stop-color: #982aa2;
            stop-color: color(display-p3 0.5961 0.1647 0.6353);
            stop-opacity: 1;
          "
        />
        <stop
          offset="1"
          stop-color="#0060FF"
          style="
            stop-color: #0060ff;
            stop-color: color(display-p3 0 0.3765 1);
            stop-opacity: 1;
          "
        />
      </linearGradient>
    </defs>
  </svg>
</template>
