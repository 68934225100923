<script setup lang="ts">
import { AppTab } from '@/shared/types/components';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import AppList from '../AppList/AppList.vue';

interface Props {
  tabs: AppTab[];
  outlined?: boolean;
  underlined?: boolean;
  replaceRoute?: boolean;
}

defineProps<Props>();

const route = useRoute();

const isCurrentPath = (path: string): boolean => path === route.path;

const { t } = useI18n();
</script>

<template>
  <AppList
    id="app-tabs"
    gap="16px"
    :items="tabs"
    :class="[
      'app-tabs',
      {
        'app-tabs--outlined': outlined,
        'app-tabs--underlined': underlined,
      },
    ]"
    data-testid="app-tabs"
  >
    <template #item="{ item }">
      <div
        :class="[
          'app-tabs__tab',
          { 'app-tabs__tab--selected': isCurrentPath(item.path) },
        ]"
        @click="item?.onClick"
        @keydown="item?.onClick"
      >
        <router-link
          class="app-tabs__tab-link"
          :to="item.path"
          :data-disabled="item?.disabled"
          :replace="replaceRoute"
        >
          <div v-if="item?.icon" class="app-tabs__tab-icon-container">
            <img
              v-if="typeof item.icon === 'string'"
              class="app-tabs__tab-image"
              :src="item.icon"
              :alt="item.name"
              data-testid="app-tab-icon-container"
            />
            <component
              :is="item.icon"
              v-else
              class="app-tabs__tab-image"
              :alt="item.name"
              data-testid="app-tab-icon-image"
            />
          </div>
          <span
            v-if="item?.name"
            class="app-tabs__tab-label"
            data-testid="app-tab-label"
          >
            {{ t(`tabs.${item.name}`) }}
          </span>
        </router-link>
      </div>
    </template>
  </AppList>
</template>

<style scoped lang="scss">
.app-tabs__tab {
  display: flex;
}

.app-tabs__tab-link[data-disabled='true'] {
  opacity: 0.2;
  pointer-events: none;
}

.app-tabs__tab-icon-container {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-tabs__tab-image {
  margin: 4px;
}

.app-tabs__tab-label {
  font-size: 20px;
  color: $blue-500;
  font-weight: 700;
  line-height: 130%;
}

[id='app-tabs']
  .app-tabs__tab:not(.app-tabs__tab--selected)
  .app-tabs__tab-label {
  opacity: 0.5;
}

.app-tabs.app-tabs--outlined {
  gap: 0;
  width: 100%;
  padding: 1px;
}

.app-tabs.app-tabs--outlined .app-tabs__tab,
.app-tabs.app-tabs--outlined .app-tabs__tab-link {
  flex: 1;
}

.app-tabs--outlined .app-tabs__tab-link {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $gray-100;
}

.app-tabs--outlined .app-tabs__tab:first-child .app-tabs__tab-link {
  border-radius: 4px 0px 0px 4px;
}

.app-tabs--outlined .app-tabs__tab:last-child .app-tabs__tab-link {
  border-radius: 0px 4px 4px 0px;
}

.app-tabs--outlined .app-tabs__tab:not(:last-child) .app-tabs__tab-link {
  border-right-width: 0;
}

.app-tabs.app-tabs--outlined .app-tabs__tab--selected .app-tabs__tab-link {
  border-color: $blue-500;
  background-color: $blue-50;
  border-right-width: 1px;
}

.app-tabs--outlined
  .app-tabs__tab--selected
  + .app-tabs__tab
  .app-tabs__tab-link {
  border-left-width: 0;
}

.app-tabs--outlined .app-tabs__tab-label {
  font-size: 13px;
  color: $gray-800;
  font-weight: 400;
  line-height: 150%;
}

.app-tabs--outlined .app-tabs__tab--selected .app-tabs__tab-label {
  color: $blue-500;
  font-weight: 700;
}

.app-tabs.app-tabs--underlined {
  gap: 0;
}

.app-tabs.app-tabs--underlined .app-tabs__tab-link {
  padding: 6px;
}

.app-tabs--underlined .app-tabs__tab--selected .app-tabs__tab-link {
  padding-bottom: 4px;
  border-bottom: 2px solid $blue-400;
}
</style>
