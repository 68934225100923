export interface ResponseMetadata {
  totalItems?: number;
  lastUpdated?: number;
}

export interface ApiResponse<T> {
  metadata: ResponseMetadata;
  results: T;
}

export interface IBase {
  reference: string;
}

export enum HttpStatus {
  BAD_REQUEST = 400,
  UNAUTHORIZED_USER = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  NO_CONNECTION = 502,
  SERVER_MAINTENANCE = 503,
  INACTIVE_USER = 700,
}

export enum RouteAuthenticationRequirement {
  AUTHENTICATED,
  UNAUTHENTICATED,
  UNIVERSAL,
}

export enum ImageType {
  COVER = 1,
  CUSTOM,
}

export interface Image {
  id: number;
  name: string;
  url: string;
  description: string;
  organizationId: number;
  imageTypeId: ImageType;
  default: boolean;
}

export enum Locale {
  PT = 'pt-PT',
  US = 'en-US',
  DE = 'de-DE',
  ES = 'es-ES',
}

export enum StatusStaticReference {
  ACTIVE = 'S_1',
  DELETED = 'S_2',
  SCHEDULED = 'S_3',
  LIVE = 'S_4',
  COMPLETED = 'S_5',
  NOT_STARTED = 'S_6',
  READY = 'S_7',
  ON_GOING = 'S_8',
  CALCULATING_RESULTS = 'S_9',
  DONE = 'S_10',
  NOT_CLAIMED = 'S_11',
  CLAIMED = 'S_12',
  INACTIVE = 'S_13',
  DELIVERY = 'S_14',
  NOT_DELIVERY = 'S_15',
  CANCELED = 'S_16',
}

export enum Feature {
  QR_CODE = 'QR_CODE',
  SURVEY_DEFAULT_QUESTIONS = 'SURVEY_DEFAULT_QUESTIONS',
  SURVEY_CUSTOM_QUESTIONS = 'SURVEY_CUSTOM_QUESTIONS',
  LEADERBOARD_OPERATOR = 'LEADERBOARD_OPERATOR',
  MY_ACTIVITY = 'MY_ACTIVITY',
  KUDOS = 'KUDOS',
  FTX = 'FTX',
  PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
  BIOMETRICS = 'BIOMETRICS',
  WORKER_SETTINGS = 'WORKER_SETTINGS',
  OPERATOR_CHALLENGE_DETAILS = 'OPERATOR_CHALLENGE_DETAILS',
  OPERATOR_LAST_COMPLETED_CHALLENGES = 'OPERATOR_LAST_COMPLETED_CHALLENGES',
  NOTIFICATION_CENTER = 'NOTIFICATION_CENTER',
  OPERATOR_ACHIEVEMENTS = 'OPERATOR_ACHIEVEMENTS',
}

export enum FeatureVersion {
  V1 = 'v1',
  V2 = 'v2',
}
export enum TypeBadge {
  DEFAULT_BADGE = 'DEFAULT_BADGE',
  MULTI_BADGE = 'MULTI_BADGE',
}

export enum TypeBadgeColor {
  DEFAULT_BACKGROUND = 'DEFAULT_BACKGROUND',
  PRIMARY_BACKGROUND = 'PRIMARY_BACKGROUND',
  SECUNDARY_BACKGROUND = 'SECUNDARY_BACKGROUND',
}

export enum TypeProgressBar {
  PRIMARY = 'PRIMARY',
  WARNING = 'WARNING',
  SECONDARY = 'SECONDARY',
  NEGATIVE = 'NEGATIVE',
  REVERSE = 'REVERSE',
}

export interface IndexedList<T> {
  [key: string]: T[];
}

export enum Pages {
  HOME = '',
  LEADERBOARD = 'leaderboard',
  PROFILE = 'profile',
  STORE = 'store',
  MORE = 'more',
}

export interface StepsButtonsLabel {
  next: string;
  last: string;
}

export enum Routes {
  CHALLENGES = '/challenges',
  // IMPORTANT: this route must be always equal to the routes send by push notifications
  CHALLENGES_OPEN = '/open-challenges',
  // IMPORTANT: this route must be always equal to the routes send by push notifications
  CHALLENGES_PAST = '/past-challenges',
  // IMPORTANT: this route must be always equal to the routes send by push notifications
  SURVEYS = '/surveys',
  // IMPORTANT: this route must be always equal to the routes send by push notifications
  STORE = '/store',
  // IMPORTANT: this route must be always equal to the routes send by push notifications
  CHALLENGE_DETAILS = '/challenges-details',
  // IMPORTANT: this route must be always equal to the routes send by push notifications
  ACHIEVEMENTS = '/achievements',
  // IMPORTANT: this route must be always equal to the routes send by push notifications
  KUDOS = '/received-kudos',
  PROFILE_ACTIVITY_SCORE = '/profiles/activity/score',
  PROFILE_ACTIVITY_CHALLENGES = '/profiles/activity/challenges',
  PROFILE_ACTIVITY_METRICS = '/profiles/activity/metrics',
  PROFILE_CUSTOMIZATION = '/profile/customization',
  AVATAR_PROFILE_CUSTOMIZATION = '/profile/customization/avatar',
  ASSET_PROFILE_CUSTOMIZATION = '/profile/customization/asset-category',
  BACKGROUND_PROFILE_CUSTOMIZATION = '/profile/customization/background',
  USERNAME_PROFILE_CUSTOMIZATION = '/profile/customization/name',
  MORE = '/more',
  ACCOUNT_SETTINGS = '/account-settings',
  DELETE_ACCOUNT = '/delete-account',
  LOGIN = '/login',
  HOME = '/',
  FAREWELL = '/farewell',
  NOTIFICATION_CENTER = '/notification-center',
}

export type VueClassAttribute = Array<string | Record<string, boolean>>;

export interface TranslationParts {
  key: string;
  parameters: Record<string, unknown>;
}
export type VoidPromiseFunction = () => Promise<void>;
