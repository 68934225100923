import { Feature } from '@/shared/types/generic';

const excludedFeatures: Feature[] = [];

const getFeatureToggles = () => ({
  features: Object.values(Feature).filter(
    (feature) => !excludedFeatures.includes(feature),
  ),
});

export default getFeatureToggles;
