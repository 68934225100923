<template>
  <div class="app-splash-screen" data-testid="app-splash-screen">
    <img
      class="app-splash-screen__background"
      data-testid="app-splash-screen-image"
      :src="background"
      alt="vaibe background"
    />
    <img
      class="app-splash-screen__logo"
      :src="logo"
      alt="vaibe logo"
      data-testid="app-splash-screen-logo"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppSplashScreen',

  data() {
    return {
      logo: '/animations/logo.gif',
      background: '/animations/background.gif',
    };
  },
});
</script>

<style scoped lang="scss">
.app-splash-screen__background {
  height: 100%;
}

.app-splash-screen__logo {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 300px;
  z-index: 5;
}

.app__splash-screen {
  animation: fadeOut ease 4s;
  -webkit-animation: fadeOut ease 4s;
  -moz-animation: fadeOut ease 4s;
  -o-animation: fadeOut ease 4s;
  -ms-animation: fadeOut ease 4s;
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  @-moz-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  @-o-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  @-ms-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
}
</style>
