<template>
  <SplashScreen v-if="showSplash" />
  <NoConnectionView v-else-if="!isOnline" />
  <AppLayout v-else>
    <router-view />
  </AppLayout>
  <AppLoader
    :state="sharedStore.loaderState"
    :target="sharedStore.loaderTarget"
    :text="sharedStore.loaderText"
    :transparent="sharedStore.loaderTransparent"
    :opaque="sharedStore.loaderOpaque"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import useProfileStore from '@/store/profile/useProfileStore';
import useUserStore from '@/store/userStore/useUserStore';
import useTokenStore from '@/store/token/useTokenStore';
import usePushNotificationsStore from '@/store/pushNotifications/usePushNotificationsStore';
import useSharedStore from '@/store/shared/useSharedStore';
import SplashScreen from '@/components/app/AppSplashScreen/AppSplashScreen.vue';
import {
  ActionPerformed,
  PushNotifications,
} from '@capacitor/push-notifications';
import { useRouter } from 'vue-router';
import useNativeSettingsStore from '@/store/nativeSettings/useNativeSettingsStore';
import { HttpStatus } from './shared/types/generic';
import useErrorStore from './store/error/useErrorStore';
import NoConnectionView from './views/NoConnectionView/NoConnectionView.vue';
import usePostHogEvents from './composables/usePostHogEvents/usePostHogEvents';
import usePosHogConfig from './composables/usePostHogConfig/usePostHogConfig';
import AppLoader from './components/app/AppLoader/AppLoader.vue';
import AppLayout from './components/layouts/AppLayout/AppLayout.vue';
import useChallengesStore from './store/challenges/useChallengesStore';
import useNativePlatform from './composables/useNativePlatform/useNativePlatform';

export default defineComponent({
  name: 'App',

  components: { SplashScreen, NoConnectionView, AppLayout, AppLoader },

  setup() {
    const { postHogEvent } = usePostHogEvents();
    const { loadToolbarPostHog, postHogSetup } = usePosHogConfig();
    const { isNativePlatform } = useNativePlatform();
    const nativeSettingsStore = useNativeSettingsStore();
    const pushNotificationsStore = usePushNotificationsStore();

    const router = useRouter();

    return {
      postHogSetup,
      postHogEvent,
      loadToolbarPostHog,
      isNativePlatform,
      router,
      nativeSettingsStore,
      pushNotificationsStore,
    };
  },

  data() {
    return {
      showSplash: true,
      isOnline: navigator.onLine,
    };
  },

  computed: {
    ...mapStores(
      useProfileStore,
      useTokenStore,
      useErrorStore,
      useUserStore,
      useSharedStore,
      useChallengesStore,
    ),

    errorCode(): HttpStatus | undefined {
      return this.errorStore.errorCode;
    },
  },

  watch: {
    errorCode: {
      immediate: true,
      handler(newErrorCode?: HttpStatus): void {
        switch (newErrorCode) {
          case HttpStatus.FORBIDDEN:
          case HttpStatus.INACTIVE_USER:
          case HttpStatus.UNAUTHORIZED_USER:
            this.tokenStore.setToken('', '', '');
            this.router.push(`/login`);
            break;
          case HttpStatus.SERVER_MAINTENANCE:
          case HttpStatus.NO_CONNECTION:
            this.router.push(`/${newErrorCode}`);
            break;
          default:
            break;
        }

        this.errorStore.setErrorCode();
      },
    },
  },

  created(): void {
    setTimeout(() => {
      this.showSplash = false;
    }, 3000);

    this.pushNotificationsStore.state.itemReference = '';

    if (this.isNativePlatform) {
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          this.pushNotificationsStore.openNotification(
            notification,
            this.router,
          );
        },
      );
    }
  },

  async mounted(): Promise<void> {
    ['online', 'offline'].forEach((eventName) => {
      window.addEventListener(eventName, (event) => {
        this.isOnline = event.type === 'online';
      });
    });

    this.loadToolbarPostHog();

    if (this.isNativePlatform) {
      const pushNotificationsAllowedOrNot =
        await this.pushNotificationsStore.requestPushNotificationsPermissions();
      this.nativeSettingsStore.allowPushNotifications =
        pushNotificationsAllowedOrNot;
      this.nativeSettingsStore.getDeviceInfo();
    }

    document.addEventListener('click', (event) => {
      const clickedElement = event.target as HTMLElement;
      const element = clickedElement.localName;
      const eventName = `clicked ${element} with name ${clickedElement.textContent}`;
      this.saveAutoCapturePostHog(eventName || '', true);
    });

    if (this.tokenStore.token) {
      this.postHogSetup();
    }
  },

  beforeUnmount(): void {
    ['online', 'offline'].forEach((eventName) => {
      window.removeEventListener(eventName, (event) => {
        this.isOnline = event.type === 'online';
      });
    });
    window.removeEventListener('beforeunload', (event) => {
      const clickedElement = event.target as HTMLElement;
      const element = clickedElement.localName;
      const eventName = `clicked ${element} with name ${clickedElement.textContent}`;
      this.saveAutoCapturePostHog(eventName || '', false);
    });
  },

  methods: {
    saveAutoCapturePostHog(eventName: string, execute: boolean): void {
      if (execute) {
        usePostHogEvents().postHogAutoCaptureEvent(eventName, {
          identifier: this.profileStore.userProfile?.user?.orgUserId ?? '',
        });
      }
    },
  },
});
</script>
