import { defineStore } from 'pinia';
import challengesService from '@/shared/services/challengesService/challengesService';
import {
  ChallengeGoalStaticReference,
  ChallengeTypeStaticReference,
  Challenge,
  LastCompletedChallenges,
  ChallengeDetails,
} from '@/shared/types/challenges';

import { StatusStaticReference } from '@/shared/types/generic';

interface State {
  openChallenges: Challenge[];
  completedChallenges: Challenge[];
  recurrencePointsMultiplier: number;
  lastCompletedChallenges?: LastCompletedChallenges;
  challengeDetails?: ChallengeDetails;
  lastSelectedChallengeReference?: string;
  challengesLoaded: boolean;
  openChallengesStatuses: StatusStaticReference[];
  pastChallengesStatuses: StatusStaticReference[];
}

export default defineStore({
  id: 'challenges',

  state: (): State => ({
    openChallenges: [],
    completedChallenges: [],
    recurrencePointsMultiplier: 0,
    lastCompletedChallenges: undefined,
    challengeDetails: undefined,
    lastSelectedChallengeReference: undefined,
    challengesLoaded: false,
    openChallengesStatuses: [
      StatusStaticReference.NOT_STARTED,
      StatusStaticReference.READY,
      StatusStaticReference.ON_GOING,
    ],
    pastChallengesStatuses: [
      StatusStaticReference.CALCULATING_RESULTS,
      StatusStaticReference.COMPLETED,
      StatusStaticReference.DONE,
      StatusStaticReference.CANCELED,
    ],
  }),

  getters: {
    isOpenChallenge(state): boolean {
      return (
        (state.challengeDetails &&
          state.openChallengesStatuses.includes(
            state.challengeDetails.statusStaticReference,
          )) ||
        false
      );
    },

    isPastChallenge(state): boolean {
      return (
        (state.challengeDetails &&
          state.pastChallengesStatuses.includes(
            state.challengeDetails.statusStaticReference,
          )) ||
        false
      );
    },
  },

  actions: {
    async loadOpenChallenges() {
      try {
        this.openChallenges = (
          await challengesService.getChallenges(...this.openChallengesStatuses)
        ).results;
      } catch (e) {
        this.openChallenges = [];
      }
    },

    async loadCompletedChallenges() {
      try {
        this.completedChallenges = (
          await challengesService.getChallenges(...this.pastChallengesStatuses)
        ).results;
      } catch (e) {
        this.completedChallenges = [];
      }
    },

    async loadChallengeDetails(reference: string): Promise<void> {
      this.challengeDetails = (
        await challengesService.getChallengeDetails(reference)
      ).results;

      this.lastSelectedChallengeReference = reference;
    },

    async loadRecurrencePointsMultiplier(): Promise<void> {
      try {
        this.recurrencePointsMultiplier = (
          await challengesService.getRecurrencePointsMultiplier()
        ).recurrentPointsMultiplier;
      } catch (e) {
        this.recurrencePointsMultiplier = 0;
      }
    },

    async loadLastCompletedChallenges(): Promise<void> {
      try {
        this.lastCompletedChallenges = (
          await challengesService.getLastCompletedChallenges()
        ).results;
      } catch (e) {
        this.lastCompletedChallenges = undefined;
      }
    },

    clearLastCompletedChallenges(): void {
      this.lastCompletedChallenges = undefined;
    },

    getGoalTranslation(
      translate: (
        key: string,
        parameters: (string | number)[] | (string | number),
      ) => string,
      game: {
        typeStaticReference: ChallengeTypeStaticReference;
        goalStaticReference?: ChallengeGoalStaticReference;
        unitMeasureName: string;
        targetAmount?: number;
      },
    ): string {
      const pluralHandler =
        game.typeStaticReference === ChallengeTypeStaticReference.ACHIEVER
          ? 1
          : 0;

      const staticReference =
        game.typeStaticReference === ChallengeTypeStaticReference.CLASSIC
          ? game.goalStaticReference
          : game.typeStaticReference;

      return translate(
        `challengesPage.challengeItem.goals.${staticReference}`,
        [
          game.targetAmount || pluralHandler,
          translate(
            `enums.${game.unitMeasureName}`,
            game.targetAmount || pluralHandler,
          ),
        ],
      );
    },
  },
});
