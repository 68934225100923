import { rest } from 'msw';
import basePath from '@/mocks/helpers/basePath';
import { StatusStaticReference } from '@/shared/types/generic';
import { AchievementName } from '@/shared/types/achievements';
import {
  completedChallenges,
  openChallenges,
  lastCompletedChallenges,
  mockChallengeDetails,
} from '@/mocks/challenges/mockChallenges';
import { dailyStats } from '@/mocks/stats/mockStats';
import {
  handleFtx,
  inventory,
  mockCustomAvatarAsset,
  mockCustomAvatarAssetCategory,
  mockProfileActivity,
  mockProfileActivityChallenges,
  mockProfileActivityMetrics,
  mockLanguages,
  profile,
  updateDetailedProfile,
  updateProfile,
  userProfileDetails,
} from '@/mocks/profile/mockProfile';
import {
  getPurchases,
  getStoreItems,
  mockAvatarCategories,
  mockAvatars,
  mockBackgroundCategories,
  mockBackgrounds,
  mockPrizes,
} from '@/mocks/store/mockStore';
import {
  ActivityEntryType,
  CustomAvatarAsset,
  CustomAvatarAssetCategory,
  DetailedProfileUpdateRequest,
  ItemType,
  ProfileUpdateRequest,
} from '@/shared/types/profile';
import mockActivityEntries from '@/mocks/profile/mockActivityEntries';
import { LeaderboardItem } from '@/shared/types/leaderboard';
import { placeholderImage } from '@/shared/constants/icons';
import {
  mockListOfSurveysResponse,
  mockSurvey,
  mockSurveyQuestionsResponse,
} from './surveys/mockSurvey';
import { getLeaderboardResponse } from './leaderboard/mockLeaderboard';
import {
  mockReceivedKudoRecords,
  mockKudoTypes,
  mockCurrentUserReceivedKudoTypes,
  mockDisabledKudoTypesToGiveToUsers,
} from './kudos/mockKudos';
import { mockIndexedUsers } from './users/mockUsers';
import getFeatureToggles from './feature/mockFeatureToggles';
import {
  achievements,
  randomAchievementWithLevel,
} from './achievements/mockAchievements';
import notificationCenter from './notificationCenter/mockNotificationCenter';

const leaderboardResponse = getLeaderboardResponse();

const leaderboardResponseReferences =
  leaderboardResponse.results.leaderboard.map(
    (leaderboardItem: LeaderboardItem) => leaderboardItem.reference,
  );

const customAvatarAssetCategories: CustomAvatarAssetCategory[] = Array.from({
  length: 3,
}).map(() => mockCustomAvatarAssetCategory());

const customAvatarAssets: CustomAvatarAsset[] =
  customAvatarAssetCategories.reduce(
    (acc: CustomAvatarAsset[], category: CustomAvatarAssetCategory) => {
      acc.push(
        ...Array.from({ length: 10 }).map(() =>
          mockCustomAvatarAsset(category.reference),
        ),
      );

      return acc;
    },
    [],
  );

const handlers = [
  rest.get(basePath('/features'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getFeatureToggles()));
  }),

  rest.get(basePath('/translations'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json('pt-PT'));
  }),

  rest.get(basePath('/challenges/challenges-status'), (_, res, ctx) => {
    const statusReferences = _.url.searchParams.getAll('staticReference');

    const statusReferencesHaveCompletedChallenges = statusReferences.some(
      (statusReference: string) =>
        statusReference === StatusStaticReference.COMPLETED,
    );

    if (statusReferencesHaveCompletedChallenges) {
      return res(ctx.status(200), ctx.json({ results: completedChallenges }));
    }

    return res(ctx.status(200), ctx.json({ results: openChallenges }));
  }),

  rest.get(basePath('/challenges/last-completed-challenges'), (_, res, ctx) => {
    const randomValueFromZeroToTen = Math.floor(Math.random() * 10);

    if (randomValueFromZeroToTen === 0) {
      return res(ctx.status(400), ctx.json({ results: {} }));
    }

    return res(
      ctx.status(200),
      ctx.json({ results: lastCompletedChallenges(randomValueFromZeroToTen) }),
    );
  }),

  rest.get(
    basePath('/challenges/operator-detail/:reference'),
    (_, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({ results: mockChallengeDetails() }),
      );
    },
  ),

  rest.get(basePath('/profiles/achievements'), (req, res, ctx) => {
    const achievementName = req.url.searchParams.get(
      'achievementNames',
    ) as AchievementName;
    if (achievementName) {
      const results = achievements()
        .filter((achievement) => achievement.name === achievementName)
        .map((achievement) => {
          if (achievement.name === achievementName) {
            return {
              ...achievement,
              currentValue: 7,
            };
          }

          return achievement;
        });

      return res(
        ctx.status(200),
        ctx.json({
          results,
        }),
      );
    }
    return res(ctx.status(200), ctx.json({ results: achievements() }));
  }),

  rest.get(
    basePath('/profiles/achievement/notification/:reference'),
    (_, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({ results: randomAchievementWithLevel() }),
      );
    },
  ),

  rest.get(basePath('/stats'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(dailyStats));
  }),

  rest.get(basePath('/leaderboards'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(leaderboardResponse));
  }),

  rest.get(basePath('/profiles'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(profile));
  }),

  rest.get(basePath('/profiles/details'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(userProfileDetails));
  }),

  rest.get(basePath('/profiles/activity/score'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockProfileActivity()));
  }),

  rest.get(basePath('/profiles/activity/challenges'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockProfileActivityChallenges()));
  }),

  rest.get(basePath('/profiles/activity/metrics'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockProfileActivityMetrics()));
  }),

  rest.get(basePath('/profiles/inventory'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ inventory, hasAllAvatars: true, hasAllBackgrounds: true }),
    );
  }),

  rest.patch(basePath('/profiles'), (req, res, ctx) => {
    try {
      updateProfile(req.body as ProfileUpdateRequest);
      return res(ctx.status(200), ctx.json(profile));
    } catch (error) {
      return res(
        ctx.status(400),
        ctx.json({
          code: (error as Error).message,
          message: `Generic error message: ${error}`,
        }),
      );
    }
  }),

  rest.patch(basePath('/profiles/ftx'), (_req, res, ctx) => {
    handleFtx();

    return res(ctx.status(200));
  }),

  rest.patch(basePath('/profiles/device-token'), (_req, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.patch(basePath('/profiles/details/operator'), (req, res, ctx) => {
    try {
      updateDetailedProfile(req.body as DetailedProfileUpdateRequest);
      return res(ctx.status(200), ctx.json(profile));
    } catch (error) {
      return res(
        ctx.status(400),
        ctx.json({
          code: (error as Error).message,
          message: `Generic error message: ${error}`,
        }),
      );
    }
  }),

  rest.get(basePath('/profiles/log-in'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.post(basePath('/ingestion/ingestion/qr-code'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),

  rest.get(basePath('/stores'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        getStoreItems(
          req.url.searchParams.get('type'),
          req.url.searchParams.get('limit'),
        ),
      ),
    );
  }),

  rest.post(basePath('/stores/buy'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({ coins: 4999 }));
  }),

  rest.get(basePath('/purchases/worker'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getPurchases()));
  }),

  rest.get(basePath('/profiles/activity'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(
        mockActivityEntries(
          req.url.searchParams.get('activityEntryType') as ActivityEntryType,
          Number(req.url.searchParams.get('dateInterval')) || undefined,
        ),
      ),
    );
  }),

  rest.get(basePath('/surveys/next-available'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockSurvey));
  }),

  rest.get(basePath('/surveys/active'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockListOfSurveysResponse));
  }),

  rest.get(basePath('/surveys/:surveyReference/questions'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockSurveyQuestionsResponse));
  }),

  rest.post(basePath('/surveys/:surveyReference/answers'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),

  rest.get(basePath('/kudos/types'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ results: { kudosTypes: mockKudoTypes() } }),
    );
  }),

  rest.get(basePath('/profiles/kudos'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ results: mockCurrentUserReceivedKudoTypes() }),
    );
  }),

  rest.get(basePath('/kudos/users'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        results: mockReceivedKudoRecords(leaderboardResponseReferences),
      }),
    );
  }),

  rest.get(basePath('/kudos/users/unavailable'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        results: mockDisabledKudoTypesToGiveToUsers(
          leaderboardResponseReferences,
        ),
      }),
    );
  }),

  rest.post(basePath('/kudos/users'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.get(basePath('/users/search-operators'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        results: mockIndexedUsers(
          leaderboardResponseReferences,
          req.url.searchParams.get('name') || '',
          req.url.searchParams.get('isQueryByTeamName') === 'true',
          5,
          leaderboardResponse.results.currentUser.reference,
        ),
      }),
    );
  }),

  rest.patch(basePath('/kudos/users/mark-as-read'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.get(basePath('/avatar-category-assets'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ results: customAvatarAssetCategories }),
    );
  }),

  rest.get(
    basePath('/avatar-assets/category-assets/:reference'),
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          results: customAvatarAssets.filter((asset: CustomAvatarAsset) => {
            return req.params.reference === asset.categoryAssetReference;
          }),
        }),
      );
    },
  ),

  rest.get(basePath('/avatar-custom/users/selected-assets'), (_, res, ctx) => {
    const firstCustomAvatarAssetOfEachCategory: CustomAvatarAsset[] =
      Object.values(
        customAvatarAssets.reduce(
          (
            acc: Record<string, CustomAvatarAsset>,
            asset: CustomAvatarAsset,
          ) => {
            if (!(asset.categoryAssetReference in acc)) {
              acc[asset.categoryAssetReference] = asset;
            }

            return acc;
          },
          {},
        ),
      );

    return res(
      ctx.status(200),
      ctx.json({
        results: {
          customAvatarActive: false,
          customAvatarImage: placeholderImage,
          assets: firstCustomAvatarAssetOfEachCategory,
        },
      }),
    );
  }),

  rest.post(basePath('/avatar-custom/users'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.get(
    basePath(`/stores/collectables/:collectableType`),
    (req, res, ctx) => {
      if (
        (Number(req.params.collectableType) as ItemType) === ItemType.AVATAR
      ) {
        return res(ctx.status(200), ctx.json(mockAvatarCategories()));
      }
      return res(ctx.status(200), ctx.json(mockBackgroundCategories()));
    },
  ),

  rest.get(basePath('/stores/prizes'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockPrizes()));
  }),

  rest.get(basePath('/stores/detail'), (req, res, ctx) => {
    const typeId: ItemType = Number(req.url.searchParams.get('typeId'));

    if (typeId === ItemType.AVATAR) {
      return res(ctx.status(200), ctx.json(mockAvatars()));
    }

    return res(ctx.status(200), ctx.json(mockBackgrounds()));
  }),

  rest.get(basePath('/languages/codes'), (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ results: { languages: mockLanguages() } }),
    );
  }),

  rest.post(basePath(`/users/language/${'teste'}`), (_, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.get(basePath('/notification-center'), (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({ results: notificationCenter() }));
  }),

  rest.patch(basePath('/notification-center'), (_, res, ctx) => {
    return res(ctx.status(200));
  }),
];

export default handlers;
