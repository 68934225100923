/* istanbul ignore file */

import { use, ComposeOption } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { BarChart, BarSeriesOption } from 'echarts/charts';

use([CanvasRenderer, GridComponent, BarChart]);

export type ECOption = ComposeOption<BarSeriesOption | GridComponentOption>;

export interface ECEventParams {
  componentType: string;
  dataIndex: number;
  data: number | string | Record<string, unknown>;
  event: unknown;
  value: number | string | unknown[];
}

export interface ECYAxisEventParams extends ECEventParams {
  targetType: string;
  yAxisIndex: number;
}

export interface ECSeriesEventParams extends ECEventParams {
  componentType: 'series';
  name: string;
  seriesType: string;
  seriesIndex: number;
  seriesName: string;
  dataIndex: number;
  dataType?: string;
  color: string;
}
