import { ApiResponse } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';
import {
  Survey,
  SurveyQuestions,
  SurveyAnswers,
  SurveyListItem,
} from '@/shared/types/surveys';

export default {
  getSurvey(): Promise<Survey> {
    return axios.get('/surveys/next-available');
  },

  getListOfSurveys(): Promise<ApiResponse<{ surveys: SurveyListItem[] }>> {
    return axios.get('/surveys/active');
  },

  getQuestionsOfSurvey(
    surveyReference: string,
  ): Promise<{ surveyQuestions: SurveyQuestions }> {
    return axios.get(`/surveys/${surveyReference}/questions`);
  },

  postAnswersOfSurvey(
    surveyAnswers: SurveyAnswers,
    surveyReference: string,
  ): Promise<void> {
    return axios.post(`/surveys/${surveyReference}/answers`, {
      ...surveyAnswers,
    });
  },
};
