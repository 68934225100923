/* Neutral Colors */
export const WHITE = '#ffffff';
export const GRAY_25 = '#fafbfb';
export const GRAY_50 = '#f6f6f6';
export const GRAY_100 = '#e1e2e2';
export const GRAY_200 = '#b9baba';
export const GRAY_300 = '#a0a1a2';
export const GRAY_400 = '#848788';
export const GRAY_500 = '#77797a';
export const GRAY_600 = '#5e6162';
export const GRAY_700 = '#45484a';
export const GRAY_800 = '#272b2d';
export const BLACK = '#1c2022';
export const BLACK_15 = '#262523';

/* Blue Tones */
export const BLUE_25 = '#f7faff';
export const BLUE_50 = '#e6efff';
export const BLUE_100 = '#b0ceff';
export const BLUE_300 = '#5494ff';
export const BLUE_400 = '#3380ff';
export const BLUE_500 = '#0060ff';
export const BLUE_700 = '#0044b5';
export const BLUE_800 = '#00358c';

/* Red Tones */
export const RED_25 = '#fff8f8';
export const RED_100 = '#fbbcbc';
export const RED_300 = '#f66e6e';
export const RED_500 = '#f12626';
export const RED_600 = '#db2323';
export const RED_700 = '#ab1b1b';
export const RED_900 = '#651010';

/* Green Tones */
export const GREEN_25 = '#f8fdfb';
export const GREEN_100 = '#bee7d9';
export const GREEN_300 = '#72ccac';
export const GREEN_500 = '#2db383';
export const GREEN_700 = '#207f5d';
export const GREEN_900 = '#134b37';

/* Yellow Tones */
export const YELLOW_25 = '#fffef9';
export const YELLOW_50 = '#fffbeb';
export const YELLOW_100 = '#fff1c1';
export const YELLOW_300 = '#fee179';
export const YELLOW_500 = '#fed237';
export const YELLOW_700 = '#b49527';
export const YELLOW_800 = '#8c741e';

/* Orange Tones */
export const ORANGE_25 = '#fffbf7';
export const ORANGE_50 = '#fef1e6';
export const ORANGE_300 = '#f8a356';
export const ORANGE_500 = '#f57603';

/* Violet Tones */
export const VIOLET_25 = '#faf9fd';
export const VIOLET_50 = '#f0ecf9';
export const VIOLET_500 = '#653dc2';

/* Purple Tones */
export const PURPLE_25 = '#fcf8fc';
export const PURPLE_50 = '#f5eaf6';
export const PURPLE_500 = '#982aa2';

/* Other colors */
export const TRANSPARENT = 'transparent';

/* Achievements */
export const ACHIEVEMENT_BRONZE = '#FF8710';
export const ACHIEVEMENT_SILVER = '#CECECE';
export const ACHIEVEMENT_GOLD = '#FFD600';
