import { Locale } from './generic';

export enum CollectableTypeName {
  AVATAR = 'item.type.avatar',
  BACKGROUND = 'item.type.background',
}

export enum ItemType {
  AVATAR = 1,
  BACKGROUND,
}

export interface Item {
  id: number;
  reference: string;
  url: string;
  name: string;
  description: string;
  type: {
    reference: string;
    name: CollectableTypeName | string;
  };
}

export type Avatar = Item;
export type Background = Item;

export interface Attributes {
  name: string;
  size: number;
  color: string;
  isBold: boolean;
  isItalic: boolean;
  isUppercase: boolean;
}

export interface Season {
  leaderboard: {
    bannerImage: string;
    crownImage: string;
    headerColor: string;
    frameColor: string;
    title: Attributes;
    subTitle: Attributes;
    description: Attributes;
  };
}

export interface UserProfileInformation {
  termsConditions: boolean;
  privacyPolicy: boolean;
  changePassword: boolean;
  orgUserId: string;
  email: string;
  orgName: string;
  username: string;
  userDescription: string;
}

export interface UserProfileConfig {
  language: Locale;
  userLanguage: Locale;
  surveyPopUp: boolean;
  showFtx: boolean;
  deviceToken: string;
  transactionCode: number;
  season?: Season;
}

export interface UserProfile {
  user: UserProfileInformation;
  userConfig: UserProfileConfig;
}

export interface UserProfileDetails {
  points: number;
  coins: number;
  position: number;
  joinedAt: number;
  activeAvatar: Avatar;
  activeBackground: Background;
}

export interface UpdateProfileDeviceToken {
  deviceToken: UserProfileConfig['deviceToken'];
}
export interface ProfileUpdateRequest {
  showFtx?: UserProfileConfig['showFtx'];
}

export interface DetailedProfileUpdateRequest {
  backgroundId?: Background['id'];
  avatarId?: Avatar['id'];
  username?: UserProfileInformation['username'];
}

export type Inventory = Item[];

export interface InventoryResponse {
  inventory: Inventory;
  hasAllAvatars: boolean;
  hasAllBackgrounds: boolean;
}

export enum ActivityEntryType {
  METRIC = 'METRIC',
  CATEGORY = 'CATEGORY',
}

export interface RankBalance {
  lastMonth: number;
  currentMonth: number;
}

export interface Activity {
  numberOfWins: number;
  highestPlacement: number;
  challengeParticipation: number;
  rankBalance?: RankBalance;
}

export interface ActivityChallenge {
  units?: number;
  endDate: number;
  startDate: number;
  metricName?: string;
  categoryName?: string;
  challengeName: string;
  unitMeasureName: string;
}

export interface ActivityMetric {
  metricName: string;
  lastUpdate: number;
  units?: number;
  unitMeasureName: string;
}

export interface MetricActivityEntry {
  name: string;
  reference: string;
  lastUpdate: number;
  numberOfItems: number;
  activityEntryType: ActivityEntryType;
  numberOfMetrics?: number;
}

export interface ProfileSite {
  name: string;
  timezone: {
    ianaTimezone: string;
    utcOffset: string;
  };
}
export interface CustomAvatarAssetCategory {
  url: string;
  name: string;
  reference: string;
  displayOrder: number;
}

export interface CustomAvatarAsset extends CustomAvatarAssetCategory {
  categoryAssetReference: string;
}

export interface GetSelectedCustomAvatarAssetsResponse {
  customAvatarImage: string;
  assets: CustomAvatarAsset[];
  customAvatarActive: boolean;
}

export interface LanguageCode {
  code: string;
}
