import {
  PushNotificationsAnswers,
  PushNotificationsData,
} from '@/shared/types/pushNotifications';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import {
  ActionPerformed,
  PushNotifications,
} from '@capacitor/push-notifications';
import { Routes } from '@/shared/types/generic';
import { defineStore } from 'pinia';
import { reactive } from 'vue';
import useNativePlatform from '@/composables/useNativePlatform/useNativePlatform';

export default defineStore('pushNotifications', () => {
  const state = reactive({
    notificationReference: '',
    action: '',
    topicKey: '',
    itemReference: '',
    navigationPathWithBiometric: '',
  });

  const requestPushNotificationsPermissions = async (): Promise<boolean> => {
    let pushNotificationsAllowed = false;
    let permStatus = await PushNotifications.checkPermissions();
    if (
      permStatus.receive === PushNotificationsAnswers.PROMPT ||
      permStatus.receive === PushNotificationsAnswers.GRANTED
    ) {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== PushNotificationsAnswers.GRANTED) {
      pushNotificationsAllowed = false;
      return pushNotificationsAllowed;
    }
    await PushNotifications.register();
    pushNotificationsAllowed = true;
    return pushNotificationsAllowed;
  };

  const addNavigationWithBiometric = (path: string): void => {
    state.navigationPathWithBiometric = path;
  };

  const clearNavigationWithBiometric = (): void => {
    state.navigationPathWithBiometric = '';
  };

  const pushNavigationPathWithBiometric = async (
    route: RouteLocationNormalizedLoaded,
    router: Router,
  ): Promise<void> => {
    const { isNativePlatform } = useNativePlatform();

    if (
      isNativePlatform &&
      route.redirectedFrom?.fullPath === '/' &&
      state.navigationPathWithBiometric
    ) {
      await router.push({
        path: state.navigationPathWithBiometric,
        query: { pushNotificationOpened: 'true' },
      });
    }

    clearNavigationWithBiometric();
  };

  const openNotification = async (
    notification: ActionPerformed,
    router: Router,
  ): Promise<void> => {
    const { notificationReference, action, topicKey, itemReference } =
      notification.notification.data as PushNotificationsData;

    state.notificationReference = notificationReference;
    state.action = action;
    state.topicKey = topicKey;
    state.itemReference = itemReference;

    let routeToBeRedirected = JSON.stringify(action).replace(/(^"|"$)/g, '');

    if (`/${routeToBeRedirected}` === Routes.CHALLENGE_DETAILS) {
      routeToBeRedirected = `${routeToBeRedirected}/${itemReference}`;
    }

    addNavigationWithBiometric(`/${routeToBeRedirected}`);

    await router.push({
      path: `/${routeToBeRedirected}`,
      query: { pushNotificationOpened: 'true' },
    });
  };

  return {
    requestPushNotificationsPermissions,
    addNavigationWithBiometric,
    clearNavigationWithBiometric,
    pushNavigationPathWithBiometric,
    openNotification,
    state,
  };
});
