import { ActivityEntryType } from '@/shared/types/profile';
import { DatePeriod } from '@/shared/types/utils';

export default (
  activityEntryType?: ActivityEntryType,
  dateInterval?: number,
) => {
  const now = new Date().getTime();

  return [
    {
      name: 'Dummy category',
      reference: 'KSLDJF-XNCWER-XCVMJS',
      numberOfItems: 200,
      activityEntryType: ActivityEntryType.CATEGORY,
      numberOfMetrics: 3,
      lastUpdate: 1683203309000,
    },
    {
      name: 'Dummy metric 1',
      reference: 'UHAYS-18HAJ-LJLDSF',
      numberOfItems: 168,
      activityEntryType: ActivityEntryType.METRIC,
      lastUpdate: now - DatePeriod.DAY,
    },
    {
      name: 'Dummy metric 2',
      reference: 'UHAYS-SLDKFJS-LJLDSF',
      numberOfItems: 168,
      activityEntryType: ActivityEntryType.METRIC,
      lastUpdate: now - DatePeriod.WEEK,
    },
    {
      name: 'Dummy metric 3',
      reference: 'UHAYS-18HAJ-SDMFSDfM',
      numberOfItems: 168,
      activityEntryType: ActivityEntryType.METRIC,
      lastUpdate: now - DatePeriod.MONTH,
    },
  ].filter(
    (activityEntry) =>
      (activityEntryType
        ? activityEntry.activityEntryType === activityEntryType
        : true) &&
      (dateInterval
        ? activityEntry.lastUpdate >= new Date().getTime() - (dateInterval || 0)
        : true),
  );
};
