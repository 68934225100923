import featureTogglesService from '@/shared/services/featureTogglesService/featureTogglesService';
import { Feature } from '@/shared/types/generic';
import { ref } from 'vue';

const features = ref<Record<Feature, boolean>>(
  Object.values(Feature).reduce(
    (acc: Record<Feature, boolean>, feature: Feature) => {
      acc[feature] = false;

      return acc;
    },
    {} as Record<Feature, boolean>,
  ),
);

const loadFeatures = async (): Promise<void> => {
  const featureList: Feature[] = [];

  try {
    featureList.push(
      ...((await featureTogglesService.getFeatureToggles())?.features ?? []),
    );
  } finally {
    featureList.forEach((feature: Feature) => {
      features.value[feature] = true;
    });
  }
};

const isFeatureEnabled = (feature: Feature): boolean => features.value[feature];

const resetFeatures = (): void => {
  Object.keys(features.value).forEach((feature: string) => {
    features.value[feature as Feature] = false;
  });
};

export default function useFeatures() {
  return { loadFeatures, isFeatureEnabled, resetFeatures };
}
