import { ApiResponse } from '@/shared/types/generic';
import {
  Survey,
  SurveyListItem,
  SurveyQuestions,
} from '@/shared/types/surveys';

export const mockSurvey = {
  reference: '123456789',
  numberOfQuestions: 8,
  expectedTime: 8,
  endDate: new Date(1694622471877).getTime(),
};

export const mockSurveys: SurveyListItem[] = [
  {
    reference: '123456789',
    numberOfQuestions: 8,
    expectedTime: 8,
    endDate: new Date(1694622471877).getTime(),
    name: 'Survey Name',
  },
];

export const mockSurveyQuestions: SurveyQuestions = {
  surveyName: 'Survey Name',
  surveyDefaults: [
    {
      reference: 'default-1',
      question: 'surveys.questions.one',
      order: 1,
    },
    {
      reference: 'default-2',
      question: 'surveys.questions.two',
      order: 2,
    },
    {
      reference: 'default-3',
      question: 'surveys.questions.three',
      order: 3,
    },
    {
      reference: 'default-4',
      question: 'surveys.questions.four',
      order: 4,
    },
    {
      reference: 'default-5',
      question: 'surveys.questions.five',
      order: 5,
    },
  ],
  surveyCustoms: [
    {
      reference: 'custom-1',
      question: 'What is your favorite color?',
      order: 6,
    },
    {
      reference: 'custom-2',
      question: 'What is your favorite animal?',
      order: 7,
    },
    {
      reference: 'custom-3',
      question: 'What is your favorite food?',
      order: 8,
    },
  ],
};

export const mockSurveyResponse: ApiResponse<{ survey: Survey }> = {
  metadata: {
    totalItems: 6,
    lastUpdated: 1650290653000,
  },
  results: {
    survey: mockSurvey,
  },
};

export const mockListOfSurveysResponse: ApiResponse<{
  surveys: SurveyListItem[];
}> = {
  metadata: {
    totalItems: 6,
    lastUpdated: 1650290653000,
  },
  results: {
    surveys: mockSurveys,
  },
};

export const mockSurveyQuestionsResponse: {
  surveyQuestions: SurveyQuestions;
} = {
  surveyQuestions: mockSurveyQuestions,
};
