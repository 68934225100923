import axios from '@/plugins/http/axios';
import { ApiResponse } from '@/shared/types/generic';
import {
  DisabledKudoTypesToGiveToUsers,
  CurrentUserReceivedKudoType,
  KudoType,
  ReceivedKudoRecord,
} from '@/shared/types/kudos';

export default {
  getActiveKudoTypes(): Promise<ApiResponse<{ kudosTypes: KudoType[] }>> {
    return axios.get('/kudos/types');
  },

  giveKudos(
    userReference: string,
    kudosTypeReferences: string[],
  ): Promise<void> {
    return axios.post('/kudos/users', { userReference, kudosTypeReferences });
  },

  getCurrentUserReceivedKudoTypes(): Promise<
    ApiResponse<CurrentUserReceivedKudoType[]>
  > {
    return axios.get('/profiles/kudos');
  },

  getDisabledKudoTypesToGiveToUsers(): Promise<
    ApiResponse<DisabledKudoTypesToGiveToUsers>
  > {
    return axios.get('/kudos/users/unavailable');
  },

  getReceivedKudosRecordByType(
    kudosTypeReference?: KudoType['reference'],
    all = false,
  ): Promise<ApiResponse<ReceivedKudoRecord[]>> {
    return axios.get('/kudos/users', {
      params: { kudosTypeReference, all },
    });
  },

  markReceivedKudosAsRead(kudosUserReferences: string[]): Promise<void> {
    return axios.patch('/kudos/users/mark-as-read', { kudosUserReferences });
  },
};
