import { defineStore } from 'pinia';
import {
  ActivityEntryType,
  Avatar,
  Background,
  UserProfileDetails,
  CustomAvatarAsset,
  CustomAvatarAssetCategory,
  Inventory,
  Item,
  MetricActivityEntry,
  ProfileSite,
  UserProfile,
  DetailedProfileUpdateRequest,
  Activity,
  ActivityChallenge,
  ActivityMetric,
  LanguageCode,
  CollectableTypeName,
} from '@/shared/types/profile';
import profileService from '@/shared/services/profileService/profileService';
import { isEmpty } from 'lodash';

interface State {
  userProfile?: UserProfile;
  userProfileDetails?: UserProfileDetails;
  inventory?: Inventory;
  activity?: Activity;
  activityChallenges: ActivityChallenge[];
  activityMetrics: ActivityMetric[];
  metricActivityEntryList: MetricActivityEntry[];
  profileSite?: ProfileSite;
  isCustomAvatarActive: boolean;
  initialCustomAvatarSource: string;
  customAvatarAssetCategories: CustomAvatarAssetCategory[];
  customAvatarAssetsByCategory: Record<string, CustomAvatarAsset[]>;
  initiallySelectedCustomAvatarAssets: CustomAvatarAsset[];
  languagesCodes: LanguageCode[];
  hasAllAvatars: boolean;
  hasAllBackgrounds: boolean;
}

export default defineStore({
  id: 'profile',

  state: (): State => ({
    userProfile: undefined,
    userProfileDetails: undefined,
    inventory: undefined,
    activity: undefined,
    activityChallenges: [],
    activityMetrics: [],
    metricActivityEntryList: [],
    profileSite: undefined,
    initialCustomAvatarSource: '',
    isCustomAvatarActive: false,
    customAvatarAssetCategories: [],
    customAvatarAssetsByCategory: {},
    initiallySelectedCustomAvatarAssets: [],
    languagesCodes: [] as LanguageCode[],
    hasAllAvatars: false,
    hasAllBackgrounds: false,
  }),

  getters: {
    inventoryItemsByIdMap(): Record<number, Item> {
      if (this.inventory) {
        return this.inventory.reduce(
          (acc: Record<number, Item>, item: Item) => {
            acc[item.id] = item;

            return acc;
          },
          {},
        );
      }

      return {};
    },

    inventoryAvatars(): Item[] {
      if (this.inventory) {
        return this.inventory.filter(
          (item: Item) => item.type.name === CollectableTypeName.AVATAR,
        );
      }

      return [];
    },

    inventoryBackgrounds(): Item[] {
      if (this.inventory) {
        return this.inventory.filter(
          (item: Item) => item.type.name === CollectableTypeName.BACKGROUND,
        );
      }

      return [];
    },

    getItemUrlById(): (itemId?: number) => string {
      return (itemId?: number) => {
        if (itemId !== undefined && itemId in this.inventoryItemsByIdMap) {
          return this.inventoryItemsByIdMap[itemId].url;
        }

        return '';
      };
    },
  },

  actions: {
    async loadProfile() {
      this.userProfile = await profileService.getProfile();
      if (
        this.userProfile.userConfig &&
        !this.userProfile.userConfig.userLanguage
      ) {
        this.userProfile.userConfig.userLanguage =
          this.userProfile.userConfig.language;
      }
    },

    async loadDetailedProfile() {
      this.userProfileDetails = await profileService.getDetailedProfile();
    },

    async loadInventory() {
      try {
        const { inventory, hasAllAvatars, hasAllBackgrounds } =
          await profileService.getInventory();

        this.inventory = inventory;

        this.hasAllAvatars = hasAllAvatars;

        this.hasAllBackgrounds = hasAllBackgrounds;
      } catch (e) {
        this.inventory = [];

        this.hasAllAvatars = false;

        this.hasAllBackgrounds = false;
      }
    },

    async updateAvatar(newAvatar: Avatar): Promise<void> {
      if (this.userProfileDetails === undefined) {
        return;
      }

      const isAvatarAlreadySet =
        this.userProfileDetails.activeAvatar.id === newAvatar.id;

      if (isAvatarAlreadySet) {
        return;
      }

      try {
        await profileService.updateDetailedProfile({
          avatarId: newAvatar.id,
        });

        this.userProfileDetails.activeAvatar = newAvatar;
      } finally {
        await this.loadProfile();
      }
    },

    async updateBackground(newBackground: Background): Promise<void> {
      if (this.userProfileDetails === undefined) {
        return;
      }

      const isBackgroundAlreadySet =
        this.userProfileDetails.activeBackground.id === newBackground.id;

      if (isBackgroundAlreadySet) {
        return;
      }

      try {
        await profileService.updateDetailedProfile({
          backgroundId: newBackground.id,
        });

        this.userProfileDetails.activeBackground = newBackground;
      } finally {
        await this.loadProfile();
      }
    },

    async updateUsername(newUserName: string): Promise<void> {
      if (this.userProfile === undefined) {
        return;
      }

      const isUsernameAlreadySet =
        this.userProfile.user.username === newUserName;

      if (isUsernameAlreadySet) {
        return;
      }

      try {
        await profileService.updateDetailedProfile({
          username: newUserName,
        });
      } finally {
        await this.loadProfile();
      }
    },

    async updateDeviceToken(deviceToken: string): Promise<void> {
      await profileService.updateDeviceToken(deviceToken);
    },

    async updateShowFtx(showFtx: boolean): Promise<void> {
      try {
        await profileService.updateFtx({
          showFtx,
        });
      } finally {
        await this.loadProfile();
      }
    },

    async loadProfileMetricActivity(
      activityEntryType?: ActivityEntryType,
      dateInterval?: number,
    ): Promise<void> {
      try {
        this.metricActivityEntryList =
          await profileService.getProfileMetricActivity(
            activityEntryType,
            dateInterval,
          );
      } catch {
        this.metricActivityEntryList = [];
      }
    },

    async loadProfileSite(): Promise<void> {
      try {
        this.profileSite = await profileService.getProfileSite();
      } catch {
        this.profileSite = {} as ProfileSite;
      }
    },

    async getCustomAvatarAssetCategories(): Promise<void> {
      try {
        this.customAvatarAssetCategories = (
          await profileService.getCustomAvatarAssetCategories()
        ).results;
      } catch {
        this.customAvatarAssetCategories = [];
      }
    },

    async getCustomAvatarAssetsByCategoryReference(
      customAvatarAssetCategoryReference: string,
    ): Promise<void> {
      try {
        this.customAvatarAssetsByCategory[customAvatarAssetCategoryReference] =
          (
            await profileService.getCustomAvatarAssetsByCategoryReference(
              customAvatarAssetCategoryReference,
            )
          ).results;
      } catch {
        this.customAvatarAssetsByCategory[customAvatarAssetCategoryReference] =
          [];
      }
    },

    async getSelectedCustomAvatarAssets(): Promise<void> {
      try {
        const { customAvatarActive, assets, customAvatarImage } = (
          await profileService.getSelectedCustomAvatarAssets()
        ).results;

        this.initiallySelectedCustomAvatarAssets = assets;

        this.isCustomAvatarActive = customAvatarActive;

        this.initialCustomAvatarSource = customAvatarImage;
      } catch {
        this.isCustomAvatarActive = false;

        this.initialCustomAvatarSource = '';

        this.initiallySelectedCustomAvatarAssets = [];
      }
    },

    async saveCustomAvatar(
      imageBase64Binary: string,
      selectedAssets: CustomAvatarAsset[],
    ): Promise<void> {
      return profileService.saveCustomAvatar(
        imageBase64Binary,
        selectedAssets.map((asset: CustomAvatarAsset) => asset.reference),
      );
    },

    async getLanguages() {
      try {
        this.languagesCodes = (
          await profileService.getLanguagesCodes()
        ).results;
      } catch (e) {
        this.languagesCodes = [];
      }
    },

    async saveProfileLanguage(codeLanguage: string): Promise<void> {
      return profileService.saveProfileLanguage(codeLanguage);
    },

    async saveProfileCustomizationSettings(
      originalAvatarId?: number,
      backgroundId?: number,
    ): Promise<void> {
      if (
        this.userProfileDetails === undefined ||
        (originalAvatarId === undefined && backgroundId === undefined)
      ) {
        return;
      }

      const request: DetailedProfileUpdateRequest = {};

      if (
        originalAvatarId &&
        this.userProfileDetails.activeAvatar.id !== originalAvatarId
      ) {
        request.avatarId = originalAvatarId;
      }

      if (
        backgroundId &&
        this.userProfileDetails.activeBackground.id !== backgroundId
      ) {
        request.backgroundId = backgroundId;
      }

      if (isEmpty(request)) {
        return;
      }

      try {
        await profileService.updateDetailedProfile(request);
      } finally {
        await this.loadProfile();
      }
    },

    async loadProfileActivity(): Promise<void> {
      try {
        this.activity = await profileService.getActivityScore();
      } catch {
        this.activity = undefined;
      }
    },

    async loadProfileActivityChallenges(): Promise<void> {
      try {
        this.activityChallenges = (
          await profileService.getActivityChallenges()
        ).results;
      } catch {
        this.activityChallenges = [];
      }
    },

    async loadProfileActivityMetrics(): Promise<void> {
      try {
        this.activityMetrics = (
          await profileService.getActivityMetrics()
        ).results;
      } catch {
        this.activityMetrics = [];
      }
    },

    async countLogin(): Promise<void> {
      await profileService.getCountLogin();
    },
  },
});
