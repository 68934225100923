<script setup lang="ts">
import AppImage from '@/components/app/AppImage/AppImage.vue';
import {
  backgroundImageError,
  imageError,
  placeholderBackground,
  placeholderImage,
} from '@/shared/constants/icons';
import useProfileStore from '@/store/profile/useProfileStore';
import { useI18n } from 'vue-i18n';

defineProps<{ title: string }>();

const profileStore = useProfileStore();

const { t } = useI18n();
</script>

<template>
  <div class="profile-banner">
    <div class="profile-banner__leading">
      <slot name="leading" class="profile-banner__body__leading" />
    </div>
    <span
      class="profile-banner__body__title"
      data-testid="profile-banner-body-title"
    >
      {{ t(title) }}
    </span>
    <AppImage
      :image-url="profileStore?.userProfileDetails?.activeAvatar.url || ''"
      image-alt="Avatar"
      :image-placeholder="placeholderImage"
      :image-error="imageError"
      class="profile-banner__avatar"
      data-testid="profile-banner-avatar"
    />
    <AppImage
      :image-url="profileStore?.userProfileDetails?.activeBackground.url || ''"
      image-alt="Background"
      :image-placeholder="placeholderBackground"
      :image-error="backgroundImageError"
      class="profile-banner__background"
      data-testid="profile-banner-background"
    />

    <div class="profile-banner__overlay" />
  </div>
</template>

<style scoped lang="scss">
.profile-banner {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 0 16px;
  min-height: 66px;
  position: relative;
  align-items: center;
}

.profile-banner__leading {
  display: flex;
}

.profile-banner__leading,
.profile-banner__body__leading,
.profile-banner__body__title {
  z-index: 2;
  color: $white;
}

.profile-banner__avatar,
.profile-banner__background,
.profile-banner__overlay {
  position: absolute;
}

.profile-banner__avatar {
  top: 12px;
  z-index: 2;
  right: 16px;
  width: 60px;
  height: 60px;
  aspect-ratio: 1;
  border-radius: 8px;
  box-shadow: $special-shadow;
}

.profile-banner__background {
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 66px;
  object-fit: cover;
}

.profile-banner__overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: $vaibe-gradient-overlay;
}
</style>
