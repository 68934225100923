import {
  Challenge,
  LastCompletedChallenges,
  ChallengeDetails,
} from '@/shared/types/challenges';
import { ApiResponse, StatusStaticReference } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';
import paramsSerializer from '@/shared/helpers/paramsSerializer/paramsSerializer';

export default {
  getLastCompletedChallenges(): Promise<ApiResponse<LastCompletedChallenges>> {
    return axios.get('/challenges/last-completed-challenges');
  },

  getChallenges(
    ...staticReference: StatusStaticReference[]
  ): Promise<ApiResponse<Challenge[]>> {
    return axios.get('/challenges/challenges-status', {
      params: { staticReference },
      paramsSerializer,
    });
  },

  getChallengeDetails(
    reference: string,
  ): Promise<ApiResponse<ChallengeDetails>> {
    return axios.get(`/challenges/operator-detail/${reference}`);
  },

  getRecurrencePointsMultiplier(): Promise<{
    recurrentPointsMultiplier: number;
  }> {
    return axios.get('/challenge-rewards/recurrent-points-multiplier');
  },
};
