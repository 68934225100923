export enum PushNotificationsAnswers {
  GRANTED = 'granted',
  PROMPT = 'prompt',
  DENIED = 'denied',
}

export interface PushNotificationsData {
  action: string;
  notificationReference: string;
  topicKey: string;
  itemReference: string;
}
