import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplashScreen = _resolveComponent("SplashScreen")!
  const _component_NoConnectionView = _resolveComponent("NoConnectionView")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_AppLoader = _resolveComponent("AppLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showSplash)
      ? (_openBlock(), _createBlock(_component_SplashScreen, { key: 0 }))
      : (!_ctx.isOnline)
        ? (_openBlock(), _createBlock(_component_NoConnectionView, { key: 1 }))
        : (_openBlock(), _createBlock(_component_AppLayout, { key: 2 }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })),
    _createVNode(_component_AppLoader, {
      state: _ctx.sharedStore.loaderState,
      target: _ctx.sharedStore.loaderTarget,
      text: _ctx.sharedStore.loaderText,
      transparent: _ctx.sharedStore.loaderTransparent,
      opaque: _ctx.sharedStore.loaderOpaque
    }, null, 8, ["state", "target", "text", "transparent", "opaque"])
  ], 64))
}