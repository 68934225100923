import _ from 'lodash';

export default (): string => {
  const prefixes = ['John', 'Alice', 'Bob', 'Eva', 'David', 'Emily'];
  const suffixes = ['Smith', 'Johnson', 'Williams', 'Jones', 'Brown', 'Davis'];

  const getRandomIndex = (max: number): number => {
    return _.random(0, max - 1);
  };

  return `${prefixes[getRandomIndex(prefixes.length)]} ${
    suffixes[getRandomIndex(suffixes.length)]
  }`;
};
