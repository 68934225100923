export const challengeLost = '/img/challenge-lost.png';
export const challengeWon = '/img/challenge-won.png';
export const clearIcon = '/img/clear-icon.svg';
export const defaultBackground = '/img/DefaultBackground.png';
export const defaultAvatar = '/img/DefaultEmblem.png';
export const dummyAvatar = '/img/dummy-avatar.png';
export const dummyBackground = '/img/dummy-background.png';
export const placeholderImage = '/img/placeholder-image.svg';
export const placeholderBackground = '/img/placeholder-background.svg';
export const dumyChallengeBackground = '/img/dummy-background-5.png';
export const toastSuccess = '/img/toast-success.svg';
export const toastWarning = '/img/toast-warning.svg';
export const toastDanger = '/img/toast-danger.svg';
export const toastInformation = '/img/toast-information.svg';
export const toastMedal = '/img/toast-medal.svg';
export const individualChallengeBackground =
  '/img/individual-challenge-background.png';
export const teamChallengeBackground = '/img/team-challenge-background.png';
export const cooperationChallengeBackground =
  '/img/cooperation-challenge-background.png';
export const teamDuelChallengeBackground =
  '/img/team-duel-challenge-background.jpg';
export const achieverChallengeBackground =
  '/img/achiever-challenge-background.jpg';
export const freeForAllChallengeBackground =
  '/img/free-for-all-challenge-background.jpg';
export const imageError = '/img/image_load_error.svg';
export const backgroundImageError = '/img/background_load_error.svg';
