<script setup lang="ts">
import { AppLayoutNavigationLink } from '@/shared/types/components';
import { computed } from 'vue';
import AppList from '@/components/app/AppList/AppList.vue';
import useFeatures from '@/composables/useFeatures/useFeatures';
import { Feature, Routes } from '@/shared/types/generic';
import IconChallengesLine from '@/assets/icons/icon-challenges-line.vue';
import IconChallengesFilled from '@/assets/icons/icon-challenges-filled.vue';
import IconProfileLine from '@/assets/icons/icon-profile-line.vue';
import IconProfileFilled from '@/assets/icons/icon-profile-filled.vue';
import IconStoreLine from '@/assets/icons/icon-store-line.vue';
import IconStoreFilled from '@/assets/icons/icon-store-filled.vue';
import IconLeaderboardLine from '@/assets/icons/icon-leaderboard-line.vue';
import IconLeaderboardFilled from '@/assets/icons/icon-leaderboard-filled.vue';

const { isFeatureEnabled } = useFeatures();

const navigationLinks = computed<AppLayoutNavigationLink[]>(() => {
  const links: AppLayoutNavigationLink[] = [
    {
      to: Routes.CHALLENGES,
      icon: IconChallengesLine,
      activeIcon: IconChallengesFilled,
      testId: 'nav-games-link',
      id: 'vaibe-ftx__challenges',
    },
    {
      to: Routes.STORE,
      icon: IconStoreLine,
      activeIcon: IconStoreFilled,
      testId: 'nav-store-link',
      id: 'vaibe-ftx__store',
    },
  ];

  if (isFeatureEnabled(Feature.LEADERBOARD_OPERATOR)) {
    links.splice(1, 0, {
      to: '/leaderboard',
      icon: IconLeaderboardLine,
      activeIcon: IconLeaderboardFilled,
      testId: 'nav-leaderboards-link',
      id: 'vaibe-ftx__leaderboard',
    });
  }

  links.push({
    to: '/profile',
    icon: IconProfileLine,
    activeIcon: IconProfileFilled,
    testId: 'nav-profile-link',
    id: 'vaibe-ftx__profile',
  });

  return links;
});
</script>

<template>
  <footer id="footer" class="app-footer" data-testid="app-layout-footer">
    <nav data-testid="app-layout-footer-nav">
      <AppList
        gap="4px"
        flex-wrap="no-wrap"
        class="app-footer-nav__list"
        :items="navigationLinks"
      >
        <template #item="{ item }">
          <router-link
            :id="item.id"
            class="app-footer-link"
            active-class="app-footer-link--active"
            :to="item.to"
            :data-testid="item.testId"
          >
            <div class="app-footer-link__icon">
              <component
                :is="item.icon"
                alt="menu-icon"
                class="app-footer-link__icon__empty"
              />
              <component
                :is="item.activeIcon"
                alt="menu-icon"
                class="app-footer-link__icon__filled"
              />
            </div>
          </router-link>
        </template>
      </AppList>
    </nav>
  </footer>
</template>

<style scoped lang="scss">
.app-footer {
  flex: 0;
  width: 100%;
  background: $white;
}

.app-footer-nav__list {
  padding: 8px 16px 0;
  justify-content: space-evenly;
}

.app-footer-link {
  height: 50px;
  display: flex;
  min-width: 66px;
  padding: 2px 4px 0;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px 8px 0 0;
  border-bottom: 2px solid transparent;
}

.app-footer-link--active {
  background: $gray-50;
  border-image: $vaibe-gradient-dark-secondary 1;
}

.app-footer-link__icon,
.app-footer-link__icon svg {
  height: 24px;
  width: 24px;
}

.app-footer-link__icon {
  position: relative;
}

.app-footer-link__icon svg {
  position: absolute;
}

.app-footer-link .app-footer-link__icon__filled {
  opacity: 0;
}
.app-footer-link .app-footer-link__icon__empty {
  opacity: 1;
}

.app-footer-link--active .app-footer-link__icon__filled {
  opacity: 1;
}

.app-footer-link--active .app-footer-link__icon__empty {
  opacity: 0;
}
</style>
