import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import notificationCenterService from '@/shared/services/notificationCenter/notificationCenterService';
import {
  INotificationItem,
  NotificationCenterStatus,
} from '@/shared/types/notificationCenter';

interface State {
  notifications: INotificationItem[];
}

export default defineStore('notificationCenter', () => {
  const state = reactive<State>({
    notifications: [],
  });

  const notificationReferenceClicked = ref('');

  const referencesOfAllNotificationsUnread = computed(() => {
    const notificationsUnread = state.notifications.filter(
      (notification: INotificationItem) =>
        notification.status === NotificationCenterStatus.UNREAD,
    );
    return notificationsUnread.map((notification) => notification.reference);
  });

  const allRead = computed(() =>
    state.notifications.every(
      (notification: INotificationItem) =>
        notification.status === NotificationCenterStatus.READ,
    ),
  );

  const loadNotificationCenter = async () => {
    const notifications =
      await notificationCenterService.getNotificationCenterItems();
    state.notifications = notifications.results;
    return notifications;
  };

  const updateNotificationsAsRead = async (notificationsList: string[]) => {
    await notificationCenterService.patchNotificationsAsRead(notificationsList);
    await loadNotificationCenter();
  };

  return {
    state,
    allRead,
    updateNotificationsAsRead,
    loadNotificationCenter,
    referencesOfAllNotificationsUnread,
    notificationReferenceClicked,
  };
});
