<script setup lang="ts">
import { BLUE_500, PURPLE_500 } from '@/shared/constants/colors';
import getRandomReference from '@/shared/helpers/getRandomReference/getRandomReference';

const uuid = getRandomReference();
</script>
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4094 5.31658H15.924C14.731 5.31658 13.538 4.81407 12.7427 4.01005C11.5497 2.70352 9.85967 2 8.16961 2H5.5848C4.19299 2 3 3.20603 3 4.61307V5.41709V20.995C3 21.598 3.39766 22 3.99416 22C4.59065 22 4.98831 21.598 4.98831 20.995V14.4623H8.16961C9.36259 14.4623 10.4562 14.9648 11.3509 15.7688C12.5439 16.9749 14.234 17.6784 15.924 17.6784H18.4094C19.9006 17.6784 20.9942 16.4724 20.9942 15.0653V7.92965C21.0936 6.42211 19.9006 5.31658 18.4094 5.31658Z"
      :fill="`url(#${uuid}_paint0_linear_7892_235156)`"
    />
    <defs>
      <linearGradient
        :id="`${uuid}_paint0_linear_7892_235156`"
        x1="18.2625"
        y1="-2.2963"
        x2="-3.01168"
        y2="12.2341"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="PURPLE_500" />
        <stop offset="1" :stop-color="BLUE_500" />
      </linearGradient>
    </defs>
  </svg>
</template>
