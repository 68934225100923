import { defineStore } from 'pinia';
import {
  OrderedQuestion,
  Survey,
  SurveyAnswers,
  SurveyListItem,
  SurveyQuestions,
} from '@/shared/types/surveys';
import surveysService from '@/shared/services/surveysService/surveysService';

export default defineStore({
  id: 'surveys',

  state: () => ({
    survey: {} as Survey,
    surveys: [] as SurveyListItem[],
    surveyQuestions: {
      surveyName: '',
      surveyDefaults: [],
      surveyCustoms: [],
    } as SurveyQuestions,
  }),

  getters: {
    questionsByOrder: (state): OrderedQuestion[] => {
      const surveyDefaults = state.surveyQuestions.surveyDefaults.map(
        (item) => {
          return {
            ...item,
            isDefault: true,
          };
        },
      );

      return [...surveyDefaults, ...state.surveyQuestions.surveyCustoms].sort(
        (a, b) => a.order - b.order,
      );
    },

    surveysReferences: (state): string[] =>
      state.surveys.map((survey) => survey.reference),
  },

  actions: {
    async loadSurvey(): Promise<void> {
      try {
        this.survey = await surveysService.getSurvey();
      } catch (e) {
        this.survey = {} as Survey;
      }
    },

    async loadListOfSurveys() {
      try {
        this.surveys = (
          await surveysService.getListOfSurveys()
        ).results.surveys;
      } catch (e) {
        this.surveys = [] as SurveyListItem[];
      }
    },

    async loadQuestionsOfSurvey(surveyReference: string) {
      try {
        this.surveyQuestions = (
          await surveysService.getQuestionsOfSurvey(surveyReference)
        ).surveyQuestions;
      } catch (e) {
        this.surveyQuestions = {
          surveyName: '',
          surveyDefaults: [],
          surveyCustoms: [],
        } as SurveyQuestions;
      }
    },

    async saveAnswersOfSurvey(
      surveyAnswers: SurveyAnswers,
      surveyReference: string,
    ) {
      try {
        await surveysService.postAnswersOfSurvey(
          surveyAnswers,
          surveyReference,
        );
      } catch (e) {
        this.survey = {} as Survey;
      }
    },
  },
});
