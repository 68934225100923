<script setup lang="ts">
import { imageError } from '@/shared/constants/icons';
import { reactive, computed, onUnmounted, onMounted, watch } from 'vue';

interface State {
  src: string;
}

interface Props {
  imageUrl: string;
  imageAlt: string;
  imageError?: string;
  width?: string;
  height?: string;
  radius?: string;
  imagePlaceholder?: string;
}

const props = withDefaults(defineProps<Props>(), {
  width: '',
  height: '',
  imagePlaceholder: '',
  radius: '13px',
  imageError,
});

const state = reactive<State>({
  src: '',
});

interface Emits {
  (e: 'update:load'): void;
}

const emit = defineEmits<Emits>();

const loading = computed<boolean>(
  () => props.imagePlaceholder !== '' && state.src === props.imagePlaceholder,
);

function changeLoad(): void {
  if (props.imagePlaceholder !== '' && state.src === props.imagePlaceholder) {
    state.src = props.imageUrl;
  } else if (state.src === props.imageUrl) {
    emit('update:load');
  }
}

watch(
  () => props.imageUrl,
  (newValue: string) => {
    state.src = newValue;
  },
);

onMounted(() => {
  state.src =
    props.imagePlaceholder !== '' ? props.imagePlaceholder : props.imageUrl;
});

onUnmounted(() => {
  state.src = props.imagePlaceholder ?? '';
});

function handleLoadError(): void {
  state.src = props.imageError;
  emit('update:load');
}
</script>

<template>
  <img
    loading="eager"
    :src="state.src"
    :alt="props.imageAlt"
    :class="[
      'app-image',
      {
        'app-image--loading': loading,
        'app-image--size': width !== '' && height !== '',
      },
    ]"
    @load="changeLoad"
    @error="handleLoadError"
  />
</template>

<style lang="scss" scoped>
.app-image {
  border-radius: v-bind(radius);
  width: 100%;
  opacity: 1;
  transition: opacity 0.7s;
}

.app-image--size {
  max-width: v-bind(width);
  max-height: v-bind(height);
}

.app-image--loading {
  opacity: 0;
}
</style>
