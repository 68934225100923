import { StatsResponse } from '@/shared/types/stats';

const metrics = [
  {
    value: 412,
    duration: 9112421,
    name: 'RF Picking',
    current: 164,
  },
  {
    value: 75,
    duration: 2112421,
    name: 'Shipping',
    current: 30,
  },
  {
    value: 36,
    duration: 1111242,
    name: 'Voice picking',
    current: 0,
  },
  {
    value: 777,
    duration: 16024214,
    name: 'Label picking',
    current: 1290,
  },
];

// eslint-disable-next-line import/prefer-default-export
export const dailyStats: StatsResponse = {
  total: {
    value: metrics.reduce((acc, curr) => acc + curr.value, 0),
    duration: metrics.reduce((acc, curr) => acc + curr.duration, 0),
  },
  metrics,
};
