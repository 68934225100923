import {
  CheckBiometryResult,
  BiometricAuth,
  AndroidBiometryStrength,
} from '@aparajita/capacitor-biometric-auth';
import { Preferences } from '@capacitor/preferences';
import { ref } from 'vue';

const isAvailableBiometric = ref(false);
const toggleBiometric = ref('');
let androidBiometryStrength = AndroidBiometryStrength.strong;

interface BiometricPorperty {
  reason: string;
  cancelTitle: string;
  iosFallbackTitle: string;
  androidTitle: string;
  androidSubtitle: string;
}

interface KeyChain {
  username: string;
  password: string;
}

enum BiometricTypes {
  KEYCHAIN_TWO = 'keychain_two',
  KEYCHAIN_ONE = 'keychain_one',
  BIOMETRIC_FROM = 'biometric_from',
  NOT_START_BIOMETRIC = 'not_start_biometric',
  START_BIOMETRIC = '',
  BIOMETRIC_TOGGLE = 'biometric_toggle',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export default function useBiometric() {
  async function updateBiometryInfo(info: CheckBiometryResult) {
    const hasBiometric = (
      await Preferences.get({ key: BiometricTypes.BIOMETRIC_TOGGLE })
    ).value;

    toggleBiometric.value = hasBiometric || '';

    if (info.isAvailable || info.deviceIsSecure) {
      if (info.isAvailable) {
        androidBiometryStrength = AndroidBiometryStrength.weak;
      }
      isAvailableBiometric.value = true;
    } else {
      isAvailableBiometric.value = false;
    }
  }

  async function verifyBiometricIsAvailable() {
    const code = await BiometricAuth.checkBiometry();
    await updateBiometryInfo(code);
  }

  async function authenticateBiometric(
    biometric: BiometricPorperty,
  ): Promise<void> {
    return BiometricAuth.authenticate({
      reason: biometric.reason,
      cancelTitle: biometric.cancelTitle,
      allowDeviceCredential: true,
      iosFallbackTitle: biometric.iosFallbackTitle,
      androidTitle: biometric.androidTitle,
      androidSubtitle: biometric.androidSubtitle,
      androidConfirmationRequired: false,
      androidBiometryStrength,
    });
  }

  async function saveKeyChain(keyChain: KeyChain): Promise<void> {
    const encodedPassword = keyChain.password ? btoa(keyChain.password) : '';
    const encodeEmail = keyChain.username ? btoa(keyChain.username) : '';

    await Preferences.set({
      key: BiometricTypes.KEYCHAIN_ONE,
      value: encodeEmail,
    });
    await Preferences.set({
      key: BiometricTypes.KEYCHAIN_TWO,
      value: encodedPassword,
    });
  }

  async function getKeyChain(): Promise<KeyChain> {
    const credential: KeyChain = { username: '', password: '' };

    credential.username =
      (await Preferences.get({ key: BiometricTypes.KEYCHAIN_ONE })).value || '';
    credential.password =
      (await Preferences.get({ key: BiometricTypes.KEYCHAIN_TWO })).value || '';

    credential.password = atob(credential.password);
    credential.username = atob(credential.username);

    return credential;
  }

  function disabledBiometricOnStartup() {
    return Preferences.set({
      key: BiometricTypes.BIOMETRIC_FROM,
      value: BiometricTypes.NOT_START_BIOMETRIC,
    });
  }

  function activateBiometricOnStartup() {
    Preferences.set({
      key: BiometricTypes.BIOMETRIC_FROM,
      value: BiometricTypes.START_BIOMETRIC,
    });
  }

  async function toggleBiometricEnabled() {
    await Preferences.set({
      key: BiometricTypes.BIOMETRIC_TOGGLE,
      value: BiometricTypes.ENABLED,
    });
  }

  async function toggleBiometricDisabled() {
    await Preferences.set({
      key: BiometricTypes.BIOMETRIC_TOGGLE,
      value: BiometricTypes.DISABLED,
    });
  }

  async function getBiometricToogle() {
    const keyToggle = (
      await Preferences.get({ key: BiometricTypes.BIOMETRIC_TOGGLE })
    ).value;
    return keyToggle;
  }

  async function getBiometricOnStartup() {
    const keyStartup = (
      await Preferences.get({ key: BiometricTypes.BIOMETRIC_FROM })
    ).value;
    return keyStartup;
  }

  async function cleanBiometrics() {
    await Preferences.set({ key: BiometricTypes.BIOMETRIC_TOGGLE, value: '' });
    await Preferences.set({ key: BiometricTypes.BIOMETRIC_FROM, value: '' });
    await Preferences.set({ key: BiometricTypes.KEYCHAIN_ONE, value: '' });
    await Preferences.set({ key: BiometricTypes.KEYCHAIN_TWO, value: '' });
  }

  return {
    verifyBiometricIsAvailable,
    authenticateBiometric,
    isAvailableBiometric,
    disabledBiometricOnStartup,
    activateBiometricOnStartup,
    toggleBiometricEnabled,
    toggleBiometricDisabled,
    getBiometricToogle,
    getBiometricOnStartup,
    saveKeyChain,
    getKeyChain,
    cleanBiometrics,
    toggleBiometric,
  };
}
