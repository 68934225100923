import axios from '@/plugins/http/axios';
import { ApiResponse } from '@/shared/types/generic';
import { INotificationItem } from '@/shared/types/notificationCenter';

export default {
  getNotificationCenterItems(): Promise<ApiResponse<INotificationItem[]>> {
    return axios.get('/notification-center');
  },

  patchNotificationsAsRead(listOfNotifications: string[]): Promise<void> {
    return axios.patch('/notification-center/mark-as-read', {
      notificationReferences: listOfNotifications,
    });
  },
};
