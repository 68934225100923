export enum AchievementType {
  AVID_COMPETITOR = 'AVID_COMPETITOR',
  BIG_SPENDER = 'BIG_SPENDER',
  PEOPLE_PERSON = 'PEOPLE_PERSON',
  VAIBE_REGULAR = 'VAIBE_REGULAR',
}

export enum AchievementName {
  AVID_COMPETITOR = 'achievement.type.name.avid_competitor',
  BIG_SPENDER = 'achievement.type.name.big_spender',
  PEOPLE_PERSON = 'achievement.type.name.people_person',
  VAIBE_REGULAR = 'achievement.type.name.vaibe_regular',
}

export enum LevelName {
  BRONZE = 'achievement.level.name.bronze',
  SILVER = 'achievement.level.name.silver',
  GOLD = 'achievement.level.name.gold',
}
export interface AchievementLevels {
  name: LevelName;
  color: string;
  target: number;
  order: number;
}

export interface Achievement {
  icon: string;
  name: AchievementName;
  currentValue: number;
  achievementLevels: AchievementLevels[];
}

export interface AchievementNotification {
  achievementType: {
    icon: string;
    name: AchievementName;
  };
  achievementLevel: AchievementLevels;
}
