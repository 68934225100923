import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Device, DeviceInfo } from '@capacitor/device';
import { App } from '@capacitor/app';

export default defineStore('nativeSettings', () => {
  const allowPushNotifications = ref(false);
  const deviceModel = ref('');
  const deviceName = ref('');
  const devicePlatformVersion = ref('');

  const getDeviceInfo = async () => {
    const infoModel: DeviceInfo = await Device.getInfo();
    const getDeviceVersion = async () => (await App.getInfo()).version;

    deviceModel.value = infoModel.model;
    // NOTE: ios and android have different behaviours so model and name are being stored
    // no_name is being used for posthog, don't change
    deviceName.value = infoModel.name || 'no_name';
    devicePlatformVersion.value = await getDeviceVersion();
  };

  return {
    allowPushNotifications,
    deviceModel,
    deviceName,
    devicePlatformVersion,
    getDeviceInfo,
  };
});
