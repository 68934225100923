import { defineStore } from 'pinia';
import userService from '@/shared/services/userService/userService';
import { AxiosResponse } from 'axios';
import { IndexedList } from '@/shared/types/generic';
import { User } from '@/shared/types/user';

interface State {
  otp?: string;
  email: string;
  indexedUsers: IndexedList<User>;
}

export default defineStore({
  id: 'user',

  state: (): State => ({
    email: '',
    otp: undefined,
    indexedUsers: {},
  }),

  getters: {
    usersByReference(state: State): Record<string, User> {
      const map: Record<string, User> = {};

      Object.keys(state.indexedUsers).forEach((key: string) => {
        state.indexedUsers[key].forEach((user: User) => {
          map[user.reference] = user;
        });
      });

      return map;
    },
  },

  actions: {
    setEmail(email: string): void {
      this.email = email;
    },

    verifyPassword(password: string): Promise<AxiosResponse> {
      return userService.verifyPassword(password);
    },

    changePassword(password: string, email?: string): Promise<AxiosResponse> {
      return userService.changePassword(
        this.email || email || '',
        password,
        this.otp,
      );
    },

    recoverPassword(email: string): Promise<AxiosResponse> {
      return userService.recoverPassword(email);
    },

    verifyCode(otp: string, email: string): Promise<AxiosResponse> {
      this.otp = otp;
      return userService.verifyCode(email, this.otp);
    },

    async getIndexedUsers(name = '', isQueryByTeamName = false): Promise<void> {
      try {
        this.indexedUsers = (
          await userService.getIndexedUsers(name, isQueryByTeamName)
        ).results;
      } catch (_) {
        this.indexedUsers = {};
      }
    },

    deleteUser(): Promise<AxiosResponse> {
      return userService.deleteUser();
    },
  },
});
