// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function paramsSerializer(params: Record<string, any>): string {
  return Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}=${v}`).join('&');
      }
      return `${key}=${value}`;
    })
    .join('&');
}
