import { Component, ComponentPublicInstance } from 'vue';
import { RankBalance } from './profile';
import { ChallengeDetails } from './challenges';
import { AchievementName, LevelName } from './achievements';

export type AppButtonType =
  | 'vaibe'
  | 'reverse-vaibe'
  | 'empty'
  | 'reverse-empty'
  | 'filled'
  | 'vaibe-dark-mode';

export interface AppSelectOption {
  label: string;
  caption?: string;
  value: unknown;
  [key: string]: unknown;
}

export interface AppTab {
  path: string;
  name?: string;
  icon?: Component | string;
  disabled?: boolean;
  onClick?: () => void;
}

export interface AppLayoutNavigationLink {
  to: string;
  icon: Component;
  testId: string;
  activeIcon: Component;
  id?: string;
}

export type AppMessageType = 'information' | 'warning' | 'danger' | 'success';

export type AppListFlexDirection = 'row' | 'column';

export type AppListFlexWrap = 'wrap' | 'no-wrap' | 'wrap-reverse';

export enum AppLayoutType {
  BASE = 'BASE',
  EMPTY = 'EMPTY',
  HEADERLESS = 'HEADERLESS',
  FOOTERLESS = 'FOOTERLESS',
  PADDINGLESS = 'PADDINGLESS',
}

export enum AppHeaderType {
  BASE,
  THIN,
  PROFILE,
}

export interface IconSettings {
  color?: string;
  colorSecondary?: string;
  colorTertiary?: string;
}

export interface Activity {
  title: string;
  value: number | RankBalance['currentMonth'] | undefined;
  previousValue?: RankBalance['lastMonth'];
  icon: Component;
  period: string;
  attribute: string;
}

export interface PasswordErrors {
  password: string;
  passwordConfirmation: string;
}

export interface IonContentRef extends ComponentPublicInstance {
  $el: HTMLIonContentElement;
}

export enum ChallengeProgressBarType {
  RANKING_PRIMARY = 'RANKING_PRIMARY',
  RANKING_SECONDARY = 'RANKING_SECONDARY',
}

export interface ChallengeProgressBarProps {
  type: ChallengeProgressBarType;
  progress?: number;
  flat?: boolean;
}

export interface ChallengeDetailsComponentsProps {
  challenge: ChallengeDetails;
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DANGER = 'danger',
  DANGER_SECONDARY = 'danger-secondary',
  DANGER_TERTIARY = 'danger-tertiary',
}

export enum ButtonSize {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
}

export interface AchievementComponentsProps {
  animate?: boolean;
  emitable?: boolean;
  size?: string;
  name: AchievementName;
  level: LevelName;
  levelColor: string;
  levelTarget: number;
  currentValue: number;
  icon: string;
}
