import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { IonicVue } from '@ionic/vue';
import App from '@/App.vue';
import router from '@/router';
import i18n from '@/plugins/i18n/i18n';
import '@/assets/scss/main.scss';
import '@/plugins/echarts';
import VueDomPurifyHTML from 'vue-dompurify-html';
import { VueQueryPlugin } from '@tanstack/vue-query';
import worker from './mocks/browser';

if (process.env.VUE_APP_USE_MOCK_SERVER === 'true') {
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const app = createApp(App);

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

app
  .use(i18n)
  .use(pinia)
  .use(router)
  .use(IonicVue)
  .use(VueDomPurifyHTML)
  .use(VueQueryPlugin);

router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount('#app');
});
