import { KUDOS_SHOWN_IN_PROFILE } from '@/shared/constants/variables';
import kudosService from '@/shared/services/kudosService/kudosService';
import { KudoType, ReceivedKudoRecord } from '@/shared/types/kudos';
import { KudosStoreState } from '@/shared/types/state';
import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';

export default defineStore('kudos', () => {
  const state = reactive<KudosStoreState>({
    activeKudoTypes: [],
    receivedKudoRecords: [],
    currentUserReceivedKudoTypes: [],
    disabledKudoTypesToGiveToUsers: {},
  });

  const kudoTypesMapByReference = computed<Record<string, KudoType>>(() =>
    state.activeKudoTypes.reduce(
      (acc: Record<string, KudoType>, kudoType: KudoType) => {
        acc[kudoType.reference] = kudoType;

        return acc;
      },
      {},
    ),
  );

  const receivedKudoRecordMapByKudoTypeReference = computed<
    Record<string, ReceivedKudoRecord[]>
  >(() =>
    state.receivedKudoRecords.reduce(
      (
        acc: Record<string, ReceivedKudoRecord[]>,
        receivedKudoRecord: ReceivedKudoRecord,
      ) => {
        if (!acc[receivedKudoRecord.kudosTypeReference]) {
          acc[receivedKudoRecord.kudosTypeReference] = [];
        }

        acc[receivedKudoRecord.kudosTypeReference].push(receivedKudoRecord);

        return acc;
      },
      {},
    ),
  );

  const receivedKudoRecordMapByKudosUserReference = computed<
    Record<string, ReceivedKudoRecord>
  >(() =>
    state.receivedKudoRecords.reduce(
      (
        acc: Record<string, ReceivedKudoRecord>,
        receivedKudoRecord: ReceivedKudoRecord,
      ) => {
        acc[receivedKudoRecord.kudosUserReference] = receivedKudoRecord;

        return acc;
      },
      {},
    ),
  );

  const areReceivedKudos = computed<boolean>(
    () => state.currentUserReceivedKudoTypes?.length > 0,
  );

  const missingNumberOfKudos = computed<number>(
    () => KUDOS_SHOWN_IN_PROFILE - state.currentUserReceivedKudoTypes.length,
  );

  const areUnreadReceivedKudos = computed<boolean>(() =>
    state.receivedKudoRecords.some(
      (receivedKudoRecord: ReceivedKudoRecord) =>
        receivedKudoRecord.kudosRead === false,
    ),
  );

  const getActiveKudoTypes = async (): Promise<void> => {
    try {
      state.activeKudoTypes = (
        await kudosService.getActiveKudoTypes()
      ).results.kudosTypes;
    } catch (_) {
      state.activeKudoTypes = [];
    }
  };

  const giveKudos = (
    userReference: string,
    kudosTypeReferences: string[],
  ): Promise<void> =>
    kudosService.giveKudos(userReference, kudosTypeReferences);

  const getCurrentUserReceivedKudoTypes = async (): Promise<void> => {
    try {
      state.currentUserReceivedKudoTypes = (
        await kudosService.getCurrentUserReceivedKudoTypes()
      ).results;
    } catch (_) {
      state.currentUserReceivedKudoTypes = [];
    }
  };

  const getDisabledKudoTypesToGiveToUsers = async (): Promise<void> => {
    try {
      state.disabledKudoTypesToGiveToUsers =
        (await kudosService.getDisabledKudoTypesToGiveToUsers()).results || {};
    } catch (_) {
      state.disabledKudoTypesToGiveToUsers = {};
    }
  };

  const getReceivedKudosRecordByType = async (
    kudosTypeReference?: KudoType['reference'],
    all = false,
  ): Promise<void> => {
    try {
      state.receivedKudoRecords = (
        await kudosService.getReceivedKudosRecordByType(kudosTypeReference, all)
      ).results;
    } catch (_) {
      state.receivedKudoRecords = [];
    }
  };

  const hasUserAvailableKudos = (userReference: string): boolean => {
    return (
      (state.disabledKudoTypesToGiveToUsers[userReference]?.length || 0) <
      state.activeKudoTypes.length
    );
  };

  const markReceivedKudosAsRead = async (
    kudosUserReferences: string[],
  ): Promise<void> => kudosService.markReceivedKudosAsRead(kudosUserReferences);

  const markUnreadKudosAsRead = (): Promise<void> =>
    markReceivedKudosAsRead(
      state.receivedKudoRecords
        .filter(
          (receivedKudoRecord: ReceivedKudoRecord) =>
            !receivedKudoRecord.kudosRead,
        )
        .map(
          (receivedKudoRecord: ReceivedKudoRecord) =>
            receivedKudoRecord.kudosUserReference,
        ),
    );

  const markReceivedKudoRecordAsReadLocally = (
    kudosUserReference: string,
  ): void => {
    receivedKudoRecordMapByKudosUserReference.value[
      kudosUserReference
    ].kudosRead = true;
  };

  const $reset = async (): Promise<void> => {
    state.activeKudoTypes = [];
    state.receivedKudoRecords = [];
    state.currentUserReceivedKudoTypes = [];
    state.disabledKudoTypesToGiveToUsers = {};
  };

  return {
    state,
    areReceivedKudos,
    kudoTypesMapByReference,
    receivedKudoRecordMapByKudoTypeReference,
    missingNumberOfKudos,
    getActiveKudoTypes,
    giveKudos,
    getCurrentUserReceivedKudoTypes,
    getDisabledKudoTypesToGiveToUsers,
    getReceivedKudosRecordByType,
    hasUserAvailableKudos,
    markReceivedKudosAsRead,
    markUnreadKudosAsRead,
    areUnreadReceivedKudos,
    receivedKudoRecordMapByKudosUserReference,
    markReceivedKudoRecordAsReadLocally,
    $reset,
  };
});
