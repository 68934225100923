import getRandomNumber from '../getRandomNumber/getRandomNumber';

export default () =>
  Array.from({ length: 5 }, () => {
    let result = '';

    let counter = 0;

    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

    while (counter < 5) {
      result += characters.charAt(
        Math.floor(getRandomNumber(characters.length)),
      );

      counter += 1;
    }

    return result;
  }).join('-');
