import { Routes } from '@/shared/types/generic';
import {
  NotificationCenterStatus,
  NotificationCenterSource,
  INotificationItem,
} from '@/shared/types/notificationCenter';
import { dumyChallengeBackground } from '@/shared/constants/icons';

export default function notificationCenter(): INotificationItem[] {
  return [
    {
      reference: '1-reference',
      itemReference: '1-itemReference',
      source: NotificationCenterSource.START_CHALLENGE,
      action: Routes.SURVEYS,
      icons: {
        active: dumyChallengeBackground,
        disabled: 'icon-disabled',
      },
      status: NotificationCenterStatus.UNREAD,
      createdAt: 1723038862,
    },
    {
      reference: '2-reference',
      itemReference: '2-itemReference',
      source: NotificationCenterSource.RECEIVED_KUDOS,
      action: Routes.KUDOS,
      icons: {
        active: dumyChallengeBackground,
        disabled: 'icon-disabled',
      },
      status: NotificationCenterStatus.UNREAD,
      createdAt: 1720354800,
    },
    {
      reference: '3-reference',
      itemReference: '3-itemReference',
      source: NotificationCenterSource.START_CHALLENGE,
      action: Routes.CHALLENGES_OPEN,
      icons: {
        active: dumyChallengeBackground,
        disabled: 'icon-disabled',
      },
      status: NotificationCenterStatus.READ,
      createdAt: 1723018800,
    },
    {
      reference: '4-reference',
      itemReference: '4-itemReference',
      source: NotificationCenterSource.STORE_BALANCE,
      action: Routes.STORE,
      icons: {
        active: dumyChallengeBackground,
        disabled: 'icon-disabled',
      },
      status: NotificationCenterStatus.READ,
      createdAt: 1723036080,
    },
  ];
}

export function notificationCenterAllRead(): INotificationItem[] {
  return [
    {
      reference: '3-reference',
      itemReference: '3-itemReference',
      source: NotificationCenterSource.START_CHALLENGE,
      action: Routes.CHALLENGES_OPEN,
      icons: {
        active: dumyChallengeBackground,
        disabled: 'icon-disabled',
      },
      status: NotificationCenterStatus.READ,
      createdAt: 1723036080,
    },
    {
      reference: '4-reference',
      itemReference: '4-itemReference',
      source: NotificationCenterSource.STORE_BALANCE,
      action: Routes.STORE,
      icons: {
        active: dumyChallengeBackground,
        disabled: 'icon-disabled',
      },
      status: NotificationCenterStatus.READ,
      createdAt: 1723036080,
    },
  ];
}
