<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8875 26.33C17.1095 26.33 18.1094 25.3455 18.1094 24.1423H13.6656C13.6656 25.3455 14.6655 26.33 15.8875 26.33ZM22.5531 19.7669V14.2977C22.5531 10.9396 20.7423 8.1284 17.5539 7.38458V6.64077C17.5539 5.73288 16.8096 5 15.8875 5C14.9654 5 14.2211 5.73288 14.2211 6.64077V7.38458C11.0438 8.1284 9.22187 10.9286 9.22187 14.2977V19.7669L7 21.9546V23.0485H24.775V21.9546L22.5531 19.7669ZM20.3312 20.8608H11.4437V14.2977C11.4437 11.585 13.1213 9.37538 15.8875 9.37538C18.6537 9.37538 20.3312 11.585 20.3312 14.2977V20.8608Z"
      fill="#45484A"
    />
  </svg>
</template>
