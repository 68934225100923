import {
  Achievement,
  AchievementName,
  AchievementNotification,
  LevelName,
} from '@/shared/types/achievements';
import {
  ACHIEVEMENT_BRONZE,
  ACHIEVEMENT_GOLD,
  ACHIEVEMENT_SILVER,
} from '@/shared/constants/colors';
import {
  vaibeRegular,
  avidCompetitor,
  bigSpender,
  peoplePerson,
} from './iconAchievements';

export function achievements(): Achievement[] {
  return [
    {
      icon: avidCompetitor,
      name: AchievementName.AVID_COMPETITOR,
      currentValue: 5,
      achievementLevels: [
        {
          name: LevelName.BRONZE,
          color: ACHIEVEMENT_BRONZE,
          target: 10,
          order: 3,
        },
        {
          name: LevelName.SILVER,
          color: ACHIEVEMENT_SILVER,
          target: 20,
          order: 2,
        },
        {
          name: LevelName.GOLD,
          color: ACHIEVEMENT_GOLD,
          target: 30,
          order: 1,
        },
      ],
    },
    {
      icon: bigSpender,
      name: AchievementName.BIG_SPENDER,
      currentValue: 5,
      achievementLevels: [
        {
          name: LevelName.BRONZE,
          color: ACHIEVEMENT_BRONZE,
          target: 10,
          order: 3,
        },
        {
          name: LevelName.SILVER,
          color: ACHIEVEMENT_SILVER,
          target: 20,
          order: 2,
        },
        {
          name: LevelName.GOLD,
          color: ACHIEVEMENT_GOLD,
          target: 30,
          order: 1,
        },
      ],
    },
    {
      icon: peoplePerson,
      name: AchievementName.PEOPLE_PERSON,
      currentValue: 5,
      achievementLevels: [
        {
          name: LevelName.BRONZE,
          color: ACHIEVEMENT_BRONZE,
          target: 10,
          order: 3,
        },
        {
          name: LevelName.SILVER,
          color: ACHIEVEMENT_SILVER,
          target: 20,
          order: 2,
        },
        {
          name: LevelName.GOLD,
          color: ACHIEVEMENT_GOLD,
          target: 30,
          order: 1,
        },
      ],
    },
    {
      icon: vaibeRegular,
      name: AchievementName.VAIBE_REGULAR,
      currentValue: 5,
      achievementLevels: [
        {
          name: LevelName.BRONZE,
          color: ACHIEVEMENT_BRONZE,
          target: 10,
          order: 3,
        },
        {
          name: LevelName.SILVER,
          color: ACHIEVEMENT_SILVER,
          target: 20,
          order: 2,
        },
        {
          name: LevelName.GOLD,
          color: ACHIEVEMENT_GOLD,
          target: 30,
          order: 1,
        },
      ],
    },
  ];
}

export function randomAchievementWithLevel(): AchievementNotification {
  const achievementsList = achievements();
  const randomAchievement =
    achievementsList[Math.floor(Math.random() * achievementsList.length)];
  const randomLevel =
    randomAchievement.achievementLevels[
      Math.floor(Math.random() * randomAchievement.achievementLevels.length)
    ];
  return {
    achievementLevel: randomLevel,
    achievementType: {
      name: randomAchievement.name,
      icon: randomAchievement.icon,
    },
  };
}
