/* istanbul ignore file */

import { I18nOptions, createI18n } from 'vue-i18n';
import enUS from '@/locales/en_US.json';
import ptPT from '@/locales/pt_PT.json';
import deDE from '@/locales/de_DE.json';
import esES from '@/locales/es_ES.json';
import { Locale } from '@/shared/types/generic';

const messages = {
  'en-US': { ...enUS },
  'pt-PT': { ...ptPT },
  'de-DE': { ...deDE },
  'es-ES': { ...esES },
} as I18nOptions['messages'];

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || Locale.US,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Locale.US,
  globalInjection: true,
  globalInstall: true,
  messages,
});
