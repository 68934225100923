<script setup lang="ts">
import { IconSettings } from '@/shared/types/components';

const props = withDefaults(defineProps<IconSettings>(), {
  color: '#3C3B39',
});
</script>
<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8 6.1L21.3 4.7C21 3.7 20 3 19 3H5C4 3 3 3.7 2.7 4.7L2.2 6.1C1.8 7.4 2 8.7 2.8 9.8C3 10.2 3.4 10.5 3.7 10.8V17.2C3.7 19.4 5.5 21.1 7.6 21.1H9.8C10.4 21.1 10.8 20.7 10.8 20.1V17.2C10.8 16.5 11.3 16 12 16C12.7 16 13.2 16.5 13.2 17.2V20.1C13.2 20.7 13.6 21.1 14.2 21.1H16.4C18.6 21.1 20.3 19.3 20.3 17.2V14C20.3 13.4 19.9 13 19.3 13C18.7 13 18.3 13.4 18.3 14V17.1C18.3 18.2 17.4 19 16.4 19H15.2V17.1C15.2 15.3 13.8 13.9 12 13.9C10.2 13.9 8.8 15.3 8.8 17.1V19H7.6C6.5 19 5.7 18.1 5.7 17.1V11.5C5.9 11.5 6 11.5 6.2 11.5C7.3 11.5 8.4 11 9.1 10.2C9.8 11.1 10.9 11.6 12 11.6C13.1 11.6 14.2 11.1 14.9 10.3C15.6 11.1 16.7 11.6 17.8 11.6C19.2 11.6 20.4 11 21.2 9.9C22 8.7 22.2 7.4 21.8 6.1ZM19.6 8.7C19.2 9.3 18.6 9.6 17.9 9.6C17 9.6 16.2 9 16 8.2C15.9 7.7 15.4 7.4 14.9 7.4C14.4 7.4 14 7.7 13.8 8.2C13.6 9 12.8 9.6 12 9.6C11.2 9.6 10.4 9 10.2 8.2C10 7.7 9.6 7.4 9.1 7.4C8.6 7.4 8.1 7.7 8 8.2C7.8 9 7 9.6 6.2 9.6C5.5 9.6 4.9 9.3 4.4 8.7C4 8.1 3.9 7.4 4.1 6.7L4.6 5.3C4.7 5.1 4.8 5 5 5H19C19.2 5 19.4 5.1 19.4 5.3L19.9 6.7C20.1 7.4 20 8.1 19.6 8.7Z"
      :fill="props.color"
    />
  </svg>
</template>
