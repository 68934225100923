import translationsService from '@/shared/services/translationsService/translationsService';
import { defineStore } from 'pinia';
import i18n from '@/plugins/i18n/i18n';
import { Locale } from '@/shared/types/generic';
import useProfileStore from '../profile/useProfileStore';

export default defineStore({
  id: 'translations',

  actions: {
    async loadTranslations() {
      const profile = useProfileStore();
      let translations = {};
      const currentLanguage: string =
        profile.userProfile?.userConfig.userLanguage || Locale.US;
      try {
        translations = await translationsService.getTranslations();
      } catch (error) {
        translations = {};
      }

      i18n.global.mergeLocaleMessage(currentLanguage, translations);
    },
  },
});
