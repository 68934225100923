<script setup lang="ts">
import ProfileBanner from '@/components/profile/ProfileBanner/ProfileBanner.vue';
import { WHITE } from '@/shared/constants/colors';
import IconArrowBack from '@/assets/icons/icon-arrow-back.vue';
import AppButton from '../AppButton/AppButton.vue';

interface Props {
  title: string;
}

defineProps<Props>();

const emit = defineEmits(['goBack']);
</script>
<template>
  <ProfileBanner :title="title">
    <template #leading>
      <AppButton
        type="empty"
        class="app-profile-header__back-button"
        @click="emit('goBack')"
      >
        <IconArrowBack :color="WHITE" alt="Go back" />
      </AppButton>
    </template>
  </ProfileBanner>
</template>
<style scoped lang="scss">
.app-profile-header__back-button {
  height: unset;
  --padding-end: 0;
  --padding-start: 0;
}
</style>
