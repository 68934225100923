<template>
  <Transition name="fade">
    <div v-if="state" class="app-loader">
      <div
        :class="[
          'app-spinner__container',
          {
            'app-spinner__container--transparent': transparent,
            'app-spinner__container--opaque': opaque,
          },
        ]"
      >
        <div class="loading-animation__contents">
          <div v-if="asset" class="loading-animation__background" />
          <IconSpinner
            :class="[
              'loading-animation__spinner',
              { 'loading-animation__spinner--asset': asset },
            ]"
          />
          <img
            v-if="asset"
            class="loading-animation__asset"
            :src="asset"
            alt="animation-asset"
          />
          <p v-if="text">{{ text }}</p>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IconSpinner from '@/assets/icons/icon-spinner.vue';

export default defineComponent({
  name: 'AppLoader',

  components: {
    IconSpinner,
  },

  props: {
    state: {
      type: Boolean,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    asset: {
      type: String,
      default: undefined,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    opaque: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      top: '',
      height: '',
      left: '',
      width: '',
      transitionDuration: 500,
      sameValueCounter: 0,
      intervalId: undefined as number | undefined,
      timeoutId: undefined as number | undefined,
    };
  },

  computed: {
    transition(): string {
      return `opacity ${this.transitionDuration}ms`;
    },
  },

  watch: {
    state: {
      immediate: true,
      handler(newVal, oldVal): void {
        if (newVal && oldVal === false) {
          this.intervalId = window.setInterval(this.updatePosition, 20);
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
          }
        } else if (newVal === false && oldVal) {
          this.resetLoader();

          this.timeoutId = window.setTimeout(() => {
            this.left = '';
            this.top = '';
            this.width = '';
            this.height = '';
            this.timeoutId = undefined;
          }, this.transitionDuration);
        }
      },
    },
  },

  mounted(): void {
    window.addEventListener('resize', this.onWindowResize);
  },

  beforeUnmount(): void {
    window.removeEventListener('resize', this.onWindowResize);
  },

  methods: {
    updatePosition(): void {
      const boundingClientRect = document
        .querySelector(this.target)
        ?.getBoundingClientRect();
      if (
        this.left === `${boundingClientRect?.left || 0}px` &&
        this.top === `${boundingClientRect?.top || 0}px` &&
        this.width === `${boundingClientRect?.width || 0}px` &&
        this.height === `${boundingClientRect?.height || 0}px`
      ) {
        this.sameValueCounter += 1;
      }
      if (this.sameValueCounter === 3) {
        this.resetLoader();
      } else {
        this.left = `${boundingClientRect?.left || 0}px`;
        this.top = `${boundingClientRect?.top || 0}px`;
        this.width = `${boundingClientRect?.width || 0}px`;
        this.height = `${boundingClientRect?.height || 0}px`;
      }
    },

    onWindowResize(): void {
      if (this.state) this.updatePosition();
    },

    resetLoader(): void {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
      }
      this.sameValueCounter = 0;
    },
  },
});
</script>

<style scoped lang="scss">
.app-loader {
  position: fixed;
  z-index: 4;
  top: v-bind(top);
  left: v-bind(left);
  height: v-bind(height);
  width: v-bind(width);
}

.app-spinner__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $white;
  border-radius: 16px;
  opacity: 0.9;
}

.app-spinner__container--transparent {
  background: transparent;
}

.app-spinner__container--opaque {
  opacity: 1;
  background: $white;
}

.fade-enter-active,
.fade-leave-active {
  transition: v-bind(transition);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loading-animation__contents {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}

.loading-animation__background {
  width: 420px;
  height: 420px;
  background-color: $gray-25;
  border-radius: 50%;
}

.loading-animation__spinner {
  width: 70px;
  height: 70px;
  animation: spin 1.5s infinite;
}

.loading-animation__spinner--asset {
  position: absolute;
  width: 290px;
  height: 290px;
}

@keyframes spin {
  0%,
  50% {
    transform: rotate(-360deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}

.loading-animation__asset {
  position: absolute;
  width: 192px;
  height: 192px;
}
</style>
